import {createContext, useContext, useState} from 'react';

const rootContext = createContext({
    authed: false,
    setAuthed: () => null,
    showMobileMenu: false,
    setShowMobileMenu: () => null,
    showTawk: false,
    setShowTawk: () => null
});

function useRootHook() {
    const [authed, setAuthed] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [showTawk, setShowTawk] = useState(false)

    return {
        authed,
        setAuthed,
        showMobileMenu,
        setShowMobileMenu,
        showTawk,
        setShowTawk
    };
}

export function RootProvider({ children }) {
    const root = useRootHook();

    return <rootContext.Provider value={root}>{children}</rootContext.Provider>;
}

export default function RootConsumer() {
    return useContext(rootContext);
}

