import {Fragment, useState} from 'react';
import {T, useTranslate} from "@tolgee/react";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {languages} from '../../config/languages.config';
import {s3Config} from '../../config/aws.config';
import Hamburger from './Hamburger';
import MainMenuElements from './MainMenuElements';
import LanguageMenu from './LanguageMenu';
import {setShowSearch, setShowMobileMenu, setPopup, setShowUserMenu} from '../../redux/states.slice';
import {CURRENCY_POPUP, LOGIN_POPUP, REGISTRATION_POPUP} from '../PopupContainer';
import helpersUtils from '../../utils/helpers.utils';
import Avatar from '../ui/Avatar';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;
const logo = `${s3Config.assetsUrl}/img/logo.svg`;

function MainMenu() {
    const { t } = useTranslate()
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
    const {lang: currentLang} = useParams();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const navigate = useNavigate();

    const balance = helpersUtils.unitAmount(userData?.player?.balance || 0, userData?.player?.currency_subunit, userData?.player?.currency_decimal_digits);
    const balanceParts = balance ?helpersUtils.getAmountParts(balance) : [0,0];

    return (
        <Fragment>
            <div className="header__top">
                <div className="header__row">
                    <div className="header__item header__item--left">
                        <Hamburger clickAction={() => dispatch(setShowMobileMenu(true))} />
                        <Link to={`/${currentLang}`} className="logo logo--fixed">
                            <img src={`${logo}`} alt="" className="logo__img"/>
                        </Link>
                        <div className="search-widget-trigger search-widget-trigger--big"
                             onClick={() => dispatch(setShowSearch(true))}>
                            <img src={`${icons}#ico_search`} alt='search-game'
                                 className="search-widget-trigger__img"/>
                            <span className="search-widget-trigger__info"><T keyName='main.menu.search_game'>Search game</T></span>
                        </div>
                        <div className={`lang ${openLanguageMenu ? 'lang--active' : ''}`}
                             onClick={() => setOpenLanguageMenu(currentState => !currentState)}>
                            <div className="lang__current">
                                <img src={`${icons}#${languages[currentLang].var1}`} className="lang__img"
                                     alt={`Flag ${languages[currentLang].language.toUpperCase()}`}/>
                            </div>
                            <ul className="lang__list">
                                <LanguageMenu/>
                            </ul>
                        </div>
                    </div>
                    <div className="header__item header__item--center">
                        <nav className="main-nav">
                            <ol className="main-nav__list">
                                <MainMenuElements/>
                            </ol>
                        </nav>
                    </div>
                    <div className="header__item header__item--right">
                        <div className="log-reg-area">
                            <div className="registration">
                                <div className="registration__row">
                                    <Link to="#registration" onClick={() => dispatch(setPopup({popup: REGISTRATION_POPUP}))}
                                          className="small-btn registration-link">
                                        <span className="small-btn__info"><T keyName='main.menu.registration'>Registration</T></span></Link>
                                    <Link to="#login" onClick={() => dispatch(setPopup({popup: LOGIN_POPUP}))}
                                          className="small-btn small-btn--black"><span
                                        className="small-btn__info"><T keyName='main.menu.login'>Login</T></span></Link>
                                </div>
                            </div>
                            <div className="login">
                                <div className="login__row">
                                    <Link to={helpersUtils.getLanguageLink('/account/deposit')}
                                          className="small-btn small-btn--green header__deposit-link"><span><T keyName='main.menu.deposit'>Deposit</T></span>
                                    </Link>
                                    <div className="user">
                                        <div className="user__header">
                                            <div className="user__short-info" onClick={() => {
                                                dispatch(setShowUserMenu(false));
                                                navigate(`#${CURRENCY_POPUP}`)}
                                            }>
                                                <div className="user__short-info-icon">
                                                    {userData?.player?.currency_symbol}
                                                </div>
                                                <div className="user__value-side">
                                                    <div className="user__value-item">
                                                        {balanceParts[0]}<sup>{balanceParts[1]}</sup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user__img-area"
                                                 onClick={() => dispatch(setShowUserMenu(true))}
                                            >
                                                <div className="user__img-box">
                                                    <Avatar />
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="user__alert">3</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default MainMenu;
