import {useLocation, useNavigate} from 'react-router-dom';

import promo2 from '../assets/img/promo/02.jpg';
import promo3 from '../assets/img/promo/03.jpg';
import promo4 from '../assets/img/promo/04.jpg';
function Bonuses() {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <main className="main">
            <div className="bonus-page">
                <section className="section">
                    <div className="wrapper">
                        <div className="tab">
                            <div className="tab__menu tab__menu--center">
                                <div
                                    onClick={() => navigate('#casino')}
                                    className={`tab__menu-item ${['#casino', ''].includes(location.hash) ? 'tab__menu-item--active' : ''}`}
                                >
                                    <div className="h2">бонусы казино</div>
                                </div>
                                <div
                                    onClick={() => navigate('#sport')}
                                    className={`tab__menu-item ${location.hash === '#sport' ? 'tab__menu-item--active' : ''}`}
                                >
                                    <div className="h2">Спортивные бонусы</div>
                                </div>
                            </div>
                            <div className="tab__content">
                                <div style={{display: ['#casino', ''].includes(location.hash) ? '' : 'none'}}>
                                    <div className="bonus-page__grid">
                                        <div className="bonus-card">
                                            <div className="bonus-card__img-box">
                                                <img src={promo3} alt="" className="bonus-card__img"/>
                                            </div>
                                            <div className="bonus-card__content">
                                                <div className="bonus-card__info-box">
                                                    <div className="bonus-card__info bonus-card__info--big">
                                                        100% приветсвенный бонус
                                                        <br/>
                                                        до 150000 рублей на депозит
                                                    </div>
                                                    <div className="bonus-card__info">
                                                        В SimCasino каждый найдет бонус на свой вкус. Соверши
                                                        первое пополнение счета и получи 100% бонус, а также до 50
                                                        фриспинов в подарок на второй день!
                                                    </div>
                                                </div>
                                                <div className="bonus-card__btn-row">
                                                    <div className="small-btn small-btn--black"><span>подробнее</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bonus-card">
                                            <div className="bonus-card__img-box">
                                                <img src={promo2} alt="" className="bonus-card__img"/>
                                            </div>
                                            <div className="bonus-card__content">
                                                <div className="bonus-card__info-box">
                                                    <div className="bonus-card__info bonus-card__info--big">
                                                        100% приветсвенный бонус
                                                        <br/>
                                                        до 150000 рублей на депозит
                                                    </div>
                                                    <div className="bonus-card__info">
                                                        В SimCasino каждый найдет бонус на свой вкус. Соверши
                                                        первое пополнение счета и получи 100% бонус, а также до 50
                                                        фриспинов в подарок на второй день!
                                                    </div>
                                                </div>
                                                <div className="bonus-card__btn-row">
                                                    <div className="small-btn small-btn--black"><span>подробнее</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bonus-card">
                                            <div className="bonus-card__img-box">
                                                <img src={promo4} alt="" className="bonus-card__img"/>
                                            </div>
                                            <div className="bonus-card__content">
                                                <div className="bonus-card__info-box">
                                                    <div className="bonus-card__info bonus-card__info--big">
                                                        200% Спортивный бонус
                                                        <br/>
                                                        до 200000 рублей на депозит
                                                    </div>
                                                </div>
                                                <div className="bonus-card__btn-row">
                                                    <div className="small-btn small-btn--black"><span>подробнее</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: location.hash === '#sport' ? '' : 'none'}}>
                                    <div className="bonus-page__grid">
                                        <div className="bonus-card">
                                            <div className="bonus-card__img-box">
                                                <img src={promo4} alt="" className="bonus-card__img"/>
                                            </div>
                                            <div className="bonus-card__content">
                                                <div className="bonus-card__info-box">
                                                    <div className="bonus-card__info bonus-card__info--big">
                                                        200% Спортивный бонус
                                                        <br/>
                                                        до 200000 рублей на депозит
                                                    </div>
                                                </div>
                                                <div className="bonus-card__btn-row">
                                                    <div className="small-btn small-btn--black"><span>подробнее</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
}

export default Bonuses;