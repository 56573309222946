import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useTranslate} from "@tolgee/react";

import gamesServices from '../services/games.services';
import Game from '../components/Game';
import helpersUtils from '../utils/helpers.utils';
import {LoadMore} from '../components/Other';
import GameThemesList from "../components/GameThemesList";

function GameTags(props) {
    const { t } = useTranslate()
    const {batchSize = 48} = props;
    const [games, setGames] = useState([]);
    const [endSize, setEndSize] = useState(batchSize);
    let {tag, theme} = useParams();

    tag = props.tag || tag || theme;

    useEffect(() => {
        tag === 'all' || props.all ?
        gamesServices.getAllGames(0, endSize).then(data => {
            setGames(data);
        })
            :
        gamesServices.getByTag(tag, 0, endSize).then(data => {
            setGames(data);
        });
    }, [tag, endSize]);

    const getTitle = () => {
        if (props.all) {
            return t('categories.menu.elements.themes', 'Themes');
        }
        if (tag === 'all') {
            return t('categories.menu.elements.all', 'All');
        }
        const tagDetail = gamesServices.getTagDetail(tag || theme || props.tag);

        if (!tagDetail) return tag;

        const translationKey = tagDetail.translationKey;
        return t(translationKey, tagDetail.title);
    }

    return (
        <section className="section">
            {props.all || theme ? (
                <div className="them-catalog">
                    <div className="wrapper">
                        <ul className="them-catalog__list">
                            <GameThemesList />
                        </ul>
                    </div>
                </div>
            ) : null}
            <div className="wrapper">
                <div className="heading">
                    <div className="heading__content">
                        <Link to={helpersUtils.getLanguageLink(`/games/${tag || 'themes'}`)} className="heading__link">
                            {getTitle()}
                        </Link>
                    </div>
                </div>
                <div className={`game-grid ${props.tag ? 'game-grid--short' : ''}`}>
                    {
                        games?.data?.map((game) => {
                            return <Game key={game.id} game={game}/>;
                        })
                    }
                </div>
                {!props.batchSize && games.hasMore && <LoadMore setEnd={setEndSize} batchSize={batchSize}/>}
            </div>
        </section>
    );
}

export default GameTags;