let translations = {};
const setTranslations = (data) => {
    if (typeof data !== 'object') {
        throw new Error('Incorrect translations');
    }
    translations = data;
};

const getTranslation = (key, placeholders = {}) => {
    let translation = translations[key] || key;
    return replacePlaceholders(translation, placeholders);
};
const _t = getTranslation;

const replacePlaceholders = (str, placeholders = {}) => {
    let placeholderKeys = Object.keys(placeholders);

    if (placeholderKeys.length === 0) {
        return str;
    }

    for (let i = 0; i < placeholderKeys.length; i++) {
        while (~str.indexOf(placeholderKeys[i])) {
            str = str.replace(placeholderKeys[i], placeholders[placeholderKeys[i]]);
        }
    }

    return str;
};
export {_t, setTranslations};
