import Slider from 'react-slick';
import {Link, useParams} from 'react-router-dom';
import {T} from "@tolgee/react";

import {s3Config} from '../../config/aws.config';

const imgUrl = `${s3Config.assetsUrl}/img`;

const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    className: "betting-event__slider"
};

function SportSlider() {
    const {lang: currentLang} = useParams();

    return (
        <section className="section">
            <div className="wrapper">
                <div className="heading">
                    <div className="heading__content">
                        <h2><T keyName="sport.slider.top_matches">Top matches</T></h2>
                    </div>
                    <div className="betting-category">
                        <ul className="betting-category__list">
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/sport`} className="betting-category__link betting-category__link--active">
                                    <img src={`${imgUrl}/betting-category/betting-1.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.football">Football</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/sport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-2.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.tennis">Tennis</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/sport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-3.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.Basketball">Basketball</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/sport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-4.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.hockey">Hockey</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/sport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-5.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.baseball">Baseball</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/esport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-6.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.dota_2">Dota 2</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/esport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-7.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.counter_strike">Counter Strike</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/esport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-8.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.fifa">Fifa</T></span>
                                    </div>
                                </Link>
                            </li>
                            <li className="betting-category__item">
                                <Link to={`/${currentLang}/esport`} className="betting-category__link">
                                    <img src={`${imgUrl}/betting-category/betting-9.png`} alt=""
                                         className="betting-category__img"/>
                                    <div className="betting-category__link-info-box">
                                        <span className="betting-category__link-info"><T keyName="sport.slider.overwatch">Overwatch</T></span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="betting-event">
                    <Slider {...settings}>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-1.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Inter</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-2.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Bayern</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-3.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">liverpool</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-4.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Real Madrid</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-5.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Barcelona</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-6.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Man. City</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-7.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Juventus</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-8.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Atletico</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-1.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Inter</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-2.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Bayern</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-3.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">liverpool</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-4.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Real Madrid</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-5.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Barcelona</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-6.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Man. City</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="betting-event__item">
                                    <div className="betting-event__info-area">
                                        <div className="betting-event__title">UEFA Champion League</div>
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-7.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Juventus</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__game-info">
                                                    <div className="betting-event__game-time">22:00</div>
                                                    <div className="betting-event__game-date">04.08.2022</div>
                                                </div>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <div className="betting-event__player">
                                                    <div className="betting-event__player-img-box">
                                                        <img src={`${imgUrl}/betting-event/event-8.png`} alt=""
                                                             className="betting-event__player-img"/>
                                                    </div>
                                                    <div className="betting-event__player-name">Atletico</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="betting-event__betting-area">
                                        <div className="betting-event__row">
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>2.02</b><span>Win1</span></Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}>
                                                    <div className="betting-event__link-frame"><span>x <b>3.02</b></span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="betting-event__row-item">
                                                <Link className="betting-event__link" to={`/${currentLang}/sport`}><b>1.82</b><span>Win2</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default SportSlider;