import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {T} from "@tolgee/react";

import SmallButton from '../../components/ui/SmallButton';
import { s3Config } from '../../config/aws.config';

const imgUrl = `${s3Config.assetsUrl}/img`;

// Placeholder Arr
const placeholderMessagesArr = [
	{
		isNew: true,
		date: '20:15 16.11.2020',
		info: 'Поздравляем Вас! Вы получили бонус в виде 20 фриспинов по акции “Азартные выходные”',
		linkUrl: '/',
		linkInfo: 'Get freespins'
	},
	{
		isNew: true,
		date: '09:00 15.11.2020',
		info: '!!! Не пропустите сегодня вечерний футбольный матч Ювентус - Спартак, который состоится на стадионе Барселоны в 23:00 MSK У вас есть отличная возможность сделать ставку в нашем разделе “СПОРТ” на вашу любимую команду и получить крупный выигрыш.',
		linkUrl: '',
		linkInfo: ''
	},
	{
		isNew: false,
		date: '09:01 15.11.2020',
		info: 'Не пропустите сегодня вечерний футбольный матч Ювентус - Спартак, который состоится на стадионе Барселоны в 23:00 MSK У вас есть отличная возможность сделать ставку в нашем разделе “СПОРТ” на вашу любимую команду и получить крупный выигрыш.',
		linkUrl: '/',
		linkInfo: 'Let\'s go!'
	},
	{
		isNew: false,
		date: '15:23 14.11.2020',
		info: 'Вам на счёт зачислен бонус за депозит 3000 рублей. Удачной игры!',
		linkUrl: '',
		linkInfo: ''
	},
];



export const MessageCard = ({ isNew, date, info, linkUrl, linkInfo, onClick }) => {
	return (
		<div className="account__shape account__shape--large">
			<div className={`account__messaage ${isNew ? 'account__messaage--new' : null}`}>
				<div className="account__messaage-close">
					<div className="btn-close" onClick={onClick}>
						<img src={`${imgUrl}/icons/icons.svg#ico_close`} alt="" className="btn-close__img" />
					</div>
				</div>
				<div className="account__messaage-date">{date}</div>
				<div className="account__messaage-info">
					{info}
				</div>
				{linkUrl && (
					<>
						<br />
						<Link to={linkUrl} className='btn'><span>{linkInfo}</span></Link>
					</>
				)
				}
			</div>
		</div>
	)
};

const AccountMessages = () => {
	const [messagesArr, setMessagesArr] = useState([]);

	const removeMsg = (msg) => {
		setMessagesArr(prevState => prevState.filter(el => el.info !== msg.info));
	}

	useEffect(() => {
		setMessagesArr(placeholderMessagesArr)
	}, []);

	return (
		<main className="main">
			<section className="section">
				<div className="wrapper">
					<div className="heading heading--between">
						<div className="heading__content">
							<h2></h2>
						</div>
						<SmallButton
							black
							onClick={() => setMessagesArr([])}
						>
							<span><T keyName="account.messages.clear_all">Clear all</T></span>
						</SmallButton>
					</div>
					<div className="account">
						{
							messagesArr.length ?
								(
									messagesArr.map((msg, id) => {
										return (
											<MessageCard
												key={`${msg.date}_${id}`}
												isNew={msg.isNew}
												date={msg.date}
												info={msg.info}
												linkUrl={msg.linkUrl}
												linkInfo={msg.linkInfo}
												onClick={() => { removeMsg(msg) }}
											/>
										)
									})
								)
								:
								(
									<h2 className='text-center'><T keyName="account.messages.no_messages">No messages</T></h2>
								)
						}
					</div>
				</div>
			</section>
		</main>
	)
}

export default AccountMessages