import {Link, useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {languageList, languages} from '../../config/languages.config';
import {s3Config} from '../../config/aws.config';
import {setShowMobileMenu} from '../../redux/states.slice';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

function LanguageMenu(props) {
    const location = useLocation();
    const {lang: currentLang} = useParams();
    const dispatch = useDispatch();

    const prefix = props.mobile ? 'mobile-' : '';

    const getLanguageLink = (language) => {
        return `/${language}${location.pathname.slice(6)}${location.search}${location.hash}`;
    };

    return (
        languageList.map((language) => {
            const current = language === currentLang;
            return (
                <li key={language} className={`${prefix}lang__item ${current ? `${prefix}lang__item--current` : ''}`}>
                    <Link to={getLanguageLink(language)}
                          onClick={() => {
                              !current && dispatch(setShowMobileMenu(false));
                          }}
                          className={`${prefix}lang__link ${current ? `${prefix}lang__link--current` : ''}`}>
                        <img src={`${icons}#${languages[language].var1}`} className={`${prefix}lang__img`}
                             alt={languages[language].name}/>
                        <span className={`${prefix}lang__info`}>{languages[language].name}</span>
                    </Link>
                </li>
            );
        })
    );
}

export default LanguageMenu;