import {Link, useLocation} from "react-router-dom";
import {T} from "@tolgee/react";
import {useEffect, useState} from "react";

import helpersUtils from "../utils/helpers.utils";
import gamesServices from "../services/games.services";

const GameThemesList = () => {
    const location = useLocation();
    let currentPath = location.pathname;
    currentPath = currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

    const [themes, setThemes] = useState([])
    useEffect(() => {
        gamesServices.getTags().then(data => {
            data && setThemes(data)
        });
    }, []);

    if(themes?.length === 0) {
        return null;
    }

    return gamesServices.getThemeTagsDetails().map((item) => {
        const themeDetails = themes?.find(t => t.name === item.name);
        let link = `${helpersUtils.getLanguageLink(`/${item.url}`)}`;
        link = link.slice(-1) === '/' ? link.slice(0, -1) : link;

        if(!themeDetails) {
            return null
        }
        return <li key={item.title} className={"them-catalog__item"}>
            <Link to={link}
                  className={`them-catalog__link ${link === currentPath ? 'them-catalog__link--active' : ''}`}>
                <img src={themeDetails.image1} alt="" className="them-catalog__link-img"/>
                <span className="them-catalog__link-info">
                    <span>
                        <T keyName={item.translationKey}>{item.title}</T>
                    </span>
                </span>
            </Link>
        </li>
        }
    );
};

export default GameThemesList;