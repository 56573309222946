import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {T} from "@tolgee/react";

import {s3Config} from '../config/aws.config';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

const SocialLogin = function () {
    const socialClick = (ev) => {
        ev.preventDefault();
        toast('Social login unavailable');
    };

    return (
        <div className="social">
            <div className="social__info"><T keyName="social.login.login_through">Or login through</T>:</div>
            <ul className="social__list">
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_facebook`} alt=""
                             className="social__img"/>
                    </Link>
                </li>
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_google`} alt="" className="social__img"/>
                    </Link>
                </li>
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_insta`} alt="" className="social__img"/>
                    </Link>
                </li>
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_mailru`} alt="" className="social__img"/>
                    </Link>
                </li>
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_twitter`} alt=""
                             className="social__img"/>
                    </Link>
                </li>
                <li className="social__item">
                    <Link to="#" className="disabled social__link" onClick={socialClick}>
                        <img src={`${icons}#social_vkontakte`} alt=""
                             className="social__img"/>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default SocialLogin;