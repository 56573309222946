import requestUtils from '../utils/request.utils';
import usersServices from './users.services';

const projectsServices = {};

projectsServices.registrationRequiredFields = async (country) => {
    try {
        return requestUtils.get('/projects/required-fields', {type: 'registration'}, true);
    } catch (err) {
        console.error(err);
    }
};

projectsServices.profileRequiredFields = async (country) => {
    try {
        return requestUtils.get('/projects/required-fields', {type: 'profile'}, true);
    } catch (err) {
        console.error(err);
    }
};

projectsServices.getAvailableCurrencies = async () => {
    try {
        return requestUtils.get('/projects/currencies', {}, true);
    } catch (err) {
        console.error(err);
    }
};

export default projectsServices;