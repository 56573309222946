import React, { useEffect, useState } from 'react';
import validator from 'validator/es';
import {T, useTranslate} from "@tolgee/react";

import helpersUtils from '../../utils/helpers.utils';

export const OptionsForm = () => {
    const defaultFields = {
        getPromotions: true,
        getSms: true,
        sendSms: true,
        notifications: true,
    };
    const [fields, setFields] = useState(defaultFields);

    useEffect(() => {

    }, []);

    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = { ...fields };

        newFields[name] = target.dataset.lowercase === 'true' ? value.toLowerCase() : value;

        setFields(newFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFields = { ...fields };
    };

    return (
        <div className="account__body">
            <div className="account__shape">
                <div className="heading">
                    <div className="heading__content">
                        <h2><T keyName="account.settings.options">Options</T></h2>
                    </div>
                </div>
                <form className="form account__form" onSubmit={handleSubmit}>
                    <fieldset>
                        <label className="checkbox">
                            <input type="checkbox" name="getPromotions" checked={fields.getPromotions} onChange={handleChange} />
                            <div className="checkbox__label-content">
                                <div className="checkbox__square"></div>
                                <T keyName="account.settings.promotion_information_email_checkbox">Receive information about promotions to your email</T>
                            </div>
                        </label>
                        <label className="checkbox">
                            <input type="checkbox" name="getSms" checked={fields.getSms} onChange={handleChange} />
                            <div className="checkbox__label-content">
                                <div className="checkbox__square"></div>
                                <T keyName="account.settings.promotion_phone_checkbox">Receive SMS about promotions on your phone</T>
                            </div>
                        </label>
                        <label className="checkbox">
                            <input type="checkbox" name="sendSms" checked={fields.sendSms} onChange={handleChange} />
                            <div className="checkbox__label-content">
                                <div className="checkbox__square"></div>
                                <T keyName="account.settings.login_code_checkbox">Send sms with login codel</T>
                            </div>
                        </label>
                        <label className="checkbox">
                            <input type="checkbox" name="notifications" checked={fields.notifications} onChange={handleChange} />
                            <div className="checkbox__label-content">
                                <div className="checkbox__square"></div>
                                <T keyName="account.settings.transaction_notification_checkbox">Receive transaction notifications</T>
                            </div>
                        </label>
                        <div className="btn-row">
                            <label className="btn btn--medium">
                                <input type="submit" />
                                <span><T keyName="account.settings.save">Save</T></span>
                            </label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export const ChangePassForm = () => {
    const { t } = useTranslate()
    const defaultFields = {};
    const [fields, setFields] = useState(defaultFields);
    const [errors, setErrors] = useState({});

    const validatorFields = {
        oldPassword: (fields, errors) => {
            if (!fields.oldPassword || validator.isEmpty(fields.oldPassword)) {
                return errors.oldPassword = `${t('change.password.insert_password_error', 'Please insert password')}`;
            }
        },
        newPassword: (fields, errors) => {
            if (!fields.newPassword || validator.isEmpty(fields.newPassword)) {
                return errors.newPassword = `${t('change.password.insert_password_error', 'Please insert password')}`;
            }
            if (fields.newPassword === fields.oldPassword) {
                return errors.newPassword = `${t('change.password.diff_password_error', 'The new password must be different')}`;
            }
        },
        repeatPassword: (fields, errors) => {
            if (!fields.repeatPassword || validator.isEmpty(fields.repeatPassword)) {
                return errors.repeatPassword = `${t('change.password.insert_password_error', 'Please insert password')}`;
            }
            if (fields.repeatPassword !== fields.newPassword) {
                return errors.repeatPassword = `${t('change.password.diff_password_error', 'The new password must be different')}`;
            }
        },
    };
    const validate = (fields, errors = {}) => {
       
        validatorFields.oldPassword(fields, errors);
        validatorFields.newPassword(fields, errors);
        validatorFields.repeatPassword(fields, errors);

        return errors;
    };

    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = { ...fields };

        newFields[name] = target.dataset.lowercase === 'true' ? value.toLowerCase() : value;

        setFields(newFields);
        setErrors({ ...errors, [name]: undefined });
    };

    const handleBlur = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);

        const newFields = { ...fields };
        const newErrors = { ...errors };

        newFields[name] = target.dataset.trim === 'true' ? value.trim() : value;

        validatorFields[name] && validatorFields[name](newFields, newErrors);

        setErrors(newErrors);
        setFields(newFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFields = { ...fields };

        const newErrors = validate(newFields);
        setErrors(newErrors);

        if (Object.keys(newErrors).length !== 0) return;
    };

    return (
        <div className="account__body">
            <div className="account__shape">
                <div className="heading">
                    <div className="heading__content">
                        <h2><T keyName="account.password.change_password_title">Change password</T></h2>
                    </div>
                </div>
                <form className="form account__form" onSubmit={handleSubmit}>
                    <fieldset>
                        <label className={`input-area ${errors.oldPassword ? 'input-area--error' : ''}`}
                            data-label={t('account.password.old_password', 'Old password')} data-error={errors.oldPassword || ''}>
                            <input
                                name="oldPassword"
                                autoComplete= ''
                                placeholder={t('account.password.old_password', 'Old password')}
                                required
                                type="password"
                                className="input-area__input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fields.oldPassword || ''}
                                data-lowercase={false}
                                data-trim={false}
                                tabIndex="1"
                            />
                        </label>
                        <label className={`input-area ${errors.newPassword ? 'input-area--error' : ''}`}
                            data-label={t('account.password.new_password', 'Old password')} data-error={errors.newPassword || ''}>
                            <input
                                name="newPassword"
                                autoComplete=''
                                placeholder={t('account.password.new_password', 'Old password')}
                                required
                                type="password"
                                className="input-area__input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fields.newPassword || ''}
                                data-lowercase={false}
                                data-trim={false}
                                tabIndex="2"
                            />
                        </label>
                        <label className={`input-area ${errors.repeatPassword ? 'input-area--error' : ''}`}
                            data-label={t('account.password.repeat_password', 'Old password')} data-error={errors.repeatPassword || ''}>
                            <input
                                name="repeatPassword"
                                autoComplete=''
                                placeholder={t('account.password.repeat_password', 'Old password')}
                                required
                                type="password"
                                className="input-area__input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={fields.repeatPassword || ''}
                                data-lowercase={false}
                                data-trim={false}
                                tabIndex="3"
                            />
                        </label>
                        <div className="btn-row">
                            <label className="btn btn--medium">
                                <input type="submit" />
                                <span><T keyName="account.password.restore">Restore</T></span>
                            </label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

const AccountSettings = () => {
    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName="account.password.settings_title">Settings</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <OptionsForm />
                            <ChangePassForm />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountSettings;