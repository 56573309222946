import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {T, useTranslate} from "@tolgee/react";

import gamesServices from '../services/games.services';
import helpersUtils from '../utils/helpers.utils';
import usersServices from '../services/users.services';
import {LOGIN_POPUP} from '../components/PopupContainer';
import {getProfileLink} from '../utils/links.utils';
import GameTags from './GameTags';

function Play(props) {
    const { t } = useTranslate();
    const [game, setGame] = useState(null);
    const {lang: currentLanguage, slug} = useParams();
    const userData = useSelector((state) => state.user.userData);
    const [relatedGames, setRelatedGames] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const isProfileCompleted = userData && usersServices.isProfileCompleted(userData);
    const isLoggedIn = userData && userData.uuid;

    useEffect(() => {
        if (!slug) return;
        const {id} = helpersUtils.splitNameAndID(slug);
        const promises = [
            gamesServices.getById(id),
            gamesServices.getByTag('recommended', 0, 12),
        ];
        Promise.all(promises).then(([game, recommendedGames]) => {
            setGame(game);
            setRelatedGames(recommendedGames);
        });
    }, [slug]);

    let playLink = gamesServices.getPlayLink(slug, currentLanguage);
    let clickAction = () => {};

    if (!isLoggedIn) {
        playLink = `#${LOGIN_POPUP}`;
    } else if (!isProfileCompleted) {
        playLink = getProfileLink();
        const message = t('play.mobile.complete_profile_message', 'Please complete your profile to play in real mode');
        clickAction = () => {
            toast(message);
        }
    }

    if (!game) {
        return null;
    }

    return (
        <main>
            <div className="play-game">
                <div className="wrapper">
                    <div className="play-game__mobile">
                        <div className="game-item">
                            <div className="game-item__box">
                                <div className="game-item__img-box" data-title={game.name}>
                                    {
                                       /* game.gametags.map(tag => {
                                            return (
                                                <div className="game-item__label">
                                                    <span className="game-item__label-info">{tag}</span>
                                                </div>
                                            )
                                        })*/
                                    }
                                    <div className="game-item__img-container">
                                        <img src={game.image1} alt={game.name}
                                             className="game-item__game-img" />
                                    </div>
                                </div>
                                <div className="game-item__title">{game.name}</div>
                            </div>
                        </div>
                        <div className="play-game__mobile-btn-box">
                            <div className="play-game__mobile-btn-row">
                                <div className={`small-btn small-btn--black play-game__btn play-game__btn--left ${gamesServices.isFavorite(game.id) ? 'game-item__btn--favorite' : ''}`}
                                     onClick={() => {
                                         gamesServices.toggleFavorite(game.id);
                                         setRefresh((val) => ++val);
                                     }}
                                >
                                    <svg version="1.1" id="layer1" xmlns="http://www.w3.org/2000/svg"
                                         width="30px"
                                         height="30px" viewBox="0 0 30 30"
                                         style={{enableBackground: 'new 0 0 30 30'}}>
                                        <path className="small-btn-st0"
                                              d="M15,7.5l2.6,4.7l5.4,1l-3.7,3.9l0.7,5.3L15,20.2l-4.9,2.3l0.7-5.3L7,13.2l5.4-1L15,7.5"/>
                                    </svg>

                                </div>
                                <Link
                                    to={playLink}
                                    className="btn"
                                    onClick={clickAction}
                                >
                                    <T keyName='play.mobile.play'>Play</T>
                                </Link>
                                <Link to={gamesServices.getDemoLink(slug, currentLanguage)}
                                      className="game-item__btn game-item__btn--right"><T keyName='play.mobile.demo'>Demo</T>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GameTags tag='recommended' batchSize={12}/>
        </main>
    );
}

export default Play;