import requestUtils from '../utils/request.utils';

const bonusesServices = {}

bonusesServices.getPlayerBonuses = async (filters) => {
    try {
        return requestUtils.get('/bonuses', filters, true);
    } catch (err) {
        console.error(err);
    }
}

bonusesServices.activate = async (uuid) => {
    try {
        return requestUtils.post(`/bonuses/activate/${uuid}`, {}, true);
    } catch (err) {
        console.error(err);
    }
}

bonusesServices.deactivate = async (uuid) => {
    try {
        return requestUtils.post(`/bonuses/deactivate/${uuid}`, {}, true);
    } catch (err) {
        console.error(err);
    }
}

export default bonusesServices;