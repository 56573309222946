import requestUtils from '../utils/request.utils';

const withdrawalsServices = {};

withdrawalsServices.index = async (filters) => {
    return requestUtils.get('/withdrawals', filters);
}

withdrawalsServices.cancel = async (uuid) => {
    return requestUtils.post(`/withdrawals/cancel/${uuid}`, {}, true);
}
export default withdrawalsServices;