import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {isMobile} from 'react-device-detect'
import {T} from "@tolgee/react";

import gamesServices from '../services/games.services';
import {s3Config} from '../config/aws.config';
import helpersUtils from '../utils/helpers.utils';
import GameTags from './GameTags';
import {useSelector} from 'react-redux';

const imgUrl = `${s3Config.assetsUrl}/img`;

function Play(props) {
    const userData = useSelector((state) => state.user.userData);
    const [game, setGame] = useState(null);
    const [launchUrl, setLaunchUrl] = useState(null);
    const [gameProvider, setGameProvider] = useState(null);
    const [fullScreen, setFullScreen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {lang: currentLanguage, slug} = useParams();
    const {type} = props;

    useEffect(() => {
        if (!slug) return;
        const {id} = helpersUtils.splitNameAndID(slug);
        gamesServices.getById(id).then((game) => {
            setGame(game);
        });
    }, [slug]);

    useEffect(() => {
        if (!game) return;
        Promise.all([
            type === 'demo' ?  gamesServices.launchDemo(game, currentLanguage, isMobile) : gamesServices.launch(game, currentLanguage, isMobile),
            gamesServices.getProvider(game.gameprovider_id)
        ]).then(([launchUrl, provider]) => {
            //launchUrl = 'https://int.bgaming-system.com/games/AllLuckyClover100/FUN?play_token=e57088b1-5f2f-40c4-b4a5-22d6bb3ed60c'
            launchUrl && setLaunchUrl(launchUrl);
            setGameProvider(provider);
            console.log(provider)
        })

    }, [game, type, userData?.player?.currency_id]);

    if (!game || !launchUrl || !gameProvider) return null;

   /* if (isMobile) {
        window.location = launchUrl;
        return null;
    }*/

    return (
        <main>
            <div className={`play-game ${fullScreen ? 'play-game--full-screen' : ''}`}>
                {
                    game.bg && <img src={gamesServices.getBgUrl(game.bg)} alt={game.name} className="play-game__img-bg"/>
                }
                <div className="play-game__box">
                    <div className="play-game__header">
                        <div className="play-game__title">
                            <Link to={`/${currentLanguage}/games/providers/${gameProvider.name}-${gameProvider.gameprovider_id}`}>
                                <img className="play-game__title-img" src={gameProvider.image1} alt=""/>
                            </Link>
                            {game.name}
                        </div>
                        {
                            type === 'demo' &&
                            <Link to={gamesServices.getPlayLink(slug, currentLanguage)} className="small-btn"><span><T keyName="play.real_play">Real play</T></span></Link>
                        }
                        <div className="play-game__action-box">
                            <div className={`action-btn ${gamesServices.isFavorite(game.id) ? 'action-btn--favorite' : ''}`}
                                 onClick={() => {
                                     gamesServices.toggleFavorite(game.id);
                                     setRefresh((val) => ++val);
                                 }}
                            >
                                <svg version="1.1" id="layer1" xmlns="http://www.w3.org/2000/svg"
                                     x="0px" y="0px" width="30px"
                                     height="30px"
                                     viewBox="0 0 30 30" style={{enableBackground: 'new 0 0 30 30'}}
                                >
                                    <path className="action-btn-st0"
                                          d="M15,7.5l2.6,4.7l5.4,1l-3.7,3.9l0.7,5.3L15,20.2l-4.9,2.3l0.7-5.3L7,13.2l5.4-1L15,7.5">
                                    </path>
                                </svg>
                            </div>
                            <div
                                className={`action-btn ${fullScreen ? 'action-btn--active' : ''}`}
                                id="fullScreen"
                                onClick={() => setFullScreen(fullScreen => !fullScreen)}>
                                <svg version="1.1" id="layer1" xmlns="http://www.w3.org/2000/svg"
                                     x="0px" y="0px" width="30px"
                                     height="30px"
                                     viewBox="0 0 30 30" style={{enableBackground: 'new 0 0 30 30'}}
                                >
                                    <path className="action-btn-st0" d="M9,9v12h12V9H9z M19,19h-8v-8h8V19z"/>
                                </svg>

                            </div>
                            <Link to={`/${currentLanguage}`} className="action-btn">
                                <img src={`${imgUrl}/icons/icons.svg#ico_close`} alt="" className="action-btn__img"/>
                            </Link>
                        </div>
                    </div>
                    <div className="play-game__game-box">
                        <iframe id="game_frame"
                                src={launchUrl}
                                title="launch"
                                allowFullScreen=""></iframe>
                    </div>
                </div>
            </div>
            <GameTags tag='recommended' batchSize={12}/>
        </main>
    );
}

export default Play;