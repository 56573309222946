import React from 'react';

const SmallButton = ({black, white, small, big, active, onClick, children, disabled = false}) => {
	const classNames = `${disabled ? 'disabled': ''} small-btn ${black ? 'small-btn--black' : ''} ${white ? 'small-btn--white' : ''} ${small ? 'small-btn--small' : ''} ${big ? 'small-btn--big' : ''} ${active ? 'small-btn--active' : ''} `;
	return (
		<div
			onClick={onClick}
			className={classNames}
		>
			{children}
		</div>
	)
}

export default SmallButton;