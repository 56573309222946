import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';

import gamesServices from '../services/games.services';
import helpersUtils from '../utils/helpers.utils';

function GameProvidersList() {
    const [gameProviders, setGameProviders] = useState([]);
    const {provider} = useParams();

    const {name} = helpersUtils.splitNameAndID(provider || '');

    useEffect(() => {
        gamesServices.getProviders().then(data => {
            data && setGameProviders(data)
        });
    }, []);

    return (
        gameProviders.map(gameProvider => {
            const count = gamesServices.getProviderGameCount(gameProvider.gameprovider_id);
            return count === 0 ? '' : (
                <div key={gameProvider.gameprovider_id}>
                    <div className="provider-catalog__item">
                        <Link to={`${helpersUtils.getLanguageLink(`/games/providers/${gameProvider.name}-${gameProvider.gameprovider_id}`)}`} className={`provider-catalog__link ${name === gameProvider.name && 'provider-catalog__link--active'}`}>
                        <span className="provider-catalog__link-img-box">
									<img src={gameProvider.image1} alt={gameProvider.title}
                                         className="provider-catalog__link-img"/>
								</span>
                            <span className="provider-catalog__link-info">{gameProvider.title}</span>
                            <span className="provider-catalog__link-count">{count}</span>
                        </Link>
                    </div>
                </div>
            )
        })
    );
}

export default GameProvidersList;