import {createBrowserRouter} from 'react-router-dom';
import Layout from '../components/Layout';
import Mail from '../Mail';
import {NavigateToHome} from '../utils/helpers.utils';
import Home from '../pages/Home';
import Tournaments from '../pages/Tournaments';
import Bonuses from '../pages/Bonuses';
import Sport from '../pages/Sport';
import GameTags from '../pages/GameTags';
import Play from '../pages/Play';
import Info from '../pages/Info';
import AccountProfile from '../pages/account/AccountProfile';
import AccountCancelWithdraw from '../pages/account/AccountCancelWithdraw';
import AccountPaymentHistory from '../pages/account/AccountPaymentHistory';
import AccountGameHistory from '../pages/account/AccountGameHistory';
import AccountCasinoBonuses from '../pages/account/AccountCasinoBonuses';
import AccountSportBonuses from '../pages/account/AccountSportBonuses';
import AccountPromocodes from '../pages/account/AccountPromocodes';
import AccountFavoriteGames from '../pages/account/AccountFavoriteGames';
import AccountVerification from '../pages/account/AccountVerification';
import AccountMessages from '../pages/account/AccountMessages';
import AccountSettings from '../pages/account/AccountSettings';
import GameProviders from '../pages/GameProviders';
import AccountDeposit from '../pages/account/AccountDeposit';
import ProtectedRoutes from './ProtectedRoute';
import PlayMobile from '../pages/PlayMobile';

const mainRoutes = createBrowserRouter([
    {
        path: '/mail/:uuid',
        element: <Mail/>
    },
    {
        path: '/',
        element: <NavigateToHome />
    },
    {
        path: '/:lang',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: <Home />
            },
            {
                path: 'sport',
                element: <Sport />
            },
            {
                path: 'esport',
                element: <Sport />
            },
            {
                path: 'tournaments',
                element: <Tournaments />
            },
            {
                path: 'bonuses',
                element: <Bonuses />
            },
            {
                path: 'live',
                element: <Sport />
            },
            {
                path: 'games',
                children: [
                    {
                        path: 'play/mobile/:slug',
                        element: <PlayMobile />
                    },
                    {
                        path: 'play/:slug',
                        element: <Play type='real'/>
                    },
                    {
                        path: 'demo/:slug',
                        element: <Play type='demo' />
                    },
                    {
                        path: '',
                        element: <NavigateToHome />//maybe a category list page instead of a redirect
                    },
                    {
                        path: ':tag',
                        element: <GameTags />
                    },
                    {
                        path: 'providers',
                        children: [
                            {
                                path: '',
                                element: <GameProviders />
                            },
                            {
                                path: ':provider',
                                element: <GameProviders />
                            },
                        ]
                    },
                    {
                        path: 'themes',
                        children: [
                            {
                                path: '',
                                element: <GameTags all={true} />
                            },
                            {
                                path: ':theme',
                                element: <GameTags />
                            },
                        ]
                    },
                ]
            },
            // {
            //     path: 'providers',
            //     children: [
            //         {
            //             path: '',
            //             element: <NavigateToHome />//maybe a provider list page instead of a redirect
            //         },
            //         {
            //             path: ':provider',
            //             element: ''
            //         },
            //     ]
            // },
            {
                path: 'info',
                children: [
                    {
                        path: '',
                        element: <NavigateToHome />//maybe a provider list page instead of a redirect
                    },
                    {
                        path: ':page',
                        element: <Info />
                    },
                ]
            },
            {
                path: 'account',
                element: <ProtectedRoutes />,
                children: [
                    {
                        path: '',
                        element: <NavigateToHome />
                    },
                    {
                        path: 'profile',
                        element: <AccountProfile />
                    },
                    {
                        path: 'deposit',
                        element: <AccountDeposit />
                    },
                    {
                        path: 'withdraw',
                        element: <AccountProfile />
                    },
                    {
                        path: 'cancel-withdrawal',
                        element: <AccountCancelWithdraw />
                    },
                    {
                        path: 'payment-history',
                        element: <AccountPaymentHistory />
                    },
                    {
                        path: 'game-history',
                        element: <AccountGameHistory />
                    },
                    {
                        path: 'favorite',
                        element: <AccountFavoriteGames category='popular' />
                    },
                    {
                        path: 'casino-bonus',
                        element: <AccountCasinoBonuses />
                    },
                    {
                        path: 'sport-bonus',
                        element: <AccountSportBonuses />
                    },
                    {
                        path: 'promocodes',
                        element: <AccountPromocodes />
                    },
                    {
                        path: 'verification',
                        element: <AccountVerification />
                    },
                    {
                        path: 'notifications',
                        element: <AccountMessages />
                    },
                    {
                        path: 'settings',
                        element: <AccountSettings />
                    },
                ]
            }
        ]
    }
]);


export default mainRoutes;

