import { createSlice } from '@reduxjs/toolkit';
import helpersUtils from '../utils/helpers.utils';

export const userDataSlice = createSlice({
    name: 'user',
    initialState: {
        userData: null,
        userProps: null
    },
    reducers: {
        setUserData: (state, action) => {
            helpersUtils.userData = action.payload;
            state.userData = action.payload;
        },
        setUserProps: (state, action) => {
            helpersUtils.userProps = action.payload;
            state.userProps = action.payload;
        },
    }
});

export const { setUserData } = userDataSlice.actions;

export default userDataSlice.reducer;