function Hamburger(props) {
    const clickAction = props.clickAction || null;
    const active = props.active ? 'hamburger--active' : '';

    return (
        <div className={`hamburger menu-trigger ${active}`} onClick={clickAction}>
            <div className="hamburger__line hamburger__line--top"></div>
            <div className="hamburger__line hamburger__line--center"></div>
            <div className="hamburger__line hamburger__line--bottom"></div>
        </div>
    )
}

export default Hamburger;