import {useTranslate} from "@tolgee/react";
import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import helpersUtils from '../../utils/helpers.utils';
import {setShowMobileMenu} from '../../redux/states.slice';

const getMenuElements = () => [
    {
        url: 'about',
        title: 'About',
        translationKey: 'info.menu.elements.about',
    },
    {
        url: 'privacy',
        title: 'Privacy policy',
        translationKey: 'info.menu.elements.privacy_policy',
    },
    {
        url: 'terms-and-conditions',
        title: 'Terms and conditions',
        translationKey: 'info.menu.elements.terms_and_conditions',
    },
    {
        url: 'bonus-policy',
        title: 'Bonus policy',
        translationKey: 'info.menu.elements.bonus_policy',
    },
    {
        url: 'responsible-gaming',
        title: 'Responsible gaming',
        translationKey: 'info.menu.elements.responsible_gaming',
    },
    {
        url: 'faq',
        title: 'FAQ',
        translationKey: 'info.menu.elements.faq',
    },
    {
        url: 'sport-rules',
        title: 'Sport rules',
        translationKey: 'info.menu.elements.sport_rules',
    },
    {
        url: 'https://loropartners.com',
        title: 'Partners',
        translationKey: 'info.menu.elements.partners',
    },
];

const InfoMenuElements = (props) => {
    const { t } = useTranslate()

    const location = useLocation();
    const dispatch = useDispatch();

    let currentPath = location.pathname;
    currentPath = currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

    return (
        getMenuElements().map((element) => {
            let link = element.url.slice(0, 4) === 'http' ?
                element.url
                    :
                `${helpersUtils.getLanguageLink(`/info/${element.url}`)}`;

            link = link.slice(-1) === '/' ? link.slice(0, -1) : link;

            return props.mobile ?
                <li key={element.url} className="sub-menu__item">
                    <Link to={link}
                          onClick={() => {
                              dispatch(setShowMobileMenu(false));
                          }}
                          className={`sub-menu__link ${link === currentPath ? 'sub-menu__link--current' : ''}`}
                    >
                        <span className="sub-menu__link-info">{t(element.translationKey, element.title)}</span>
                    </Link>
                </li>
                :
                <li key={element.url} className="footer-nav__item">
                    <Link to={link}
                          className={`footer-nav__link ${link === currentPath ? 'footer-nav__link--current' : ''}`}>
                        <span className="footer-nav__link-info">{t(element.translationKey, element.title)}</span>
                    </Link>
                </li>;
        })
    );
};

export default InfoMenuElements;