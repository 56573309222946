import {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslate} from "@tolgee/react";
import {Link, useLocation, useParams} from 'react-router-dom';

import helpersUtils from '../../utils/helpers.utils';
import {setShowMobileMenu} from '../../redux/states.slice';
import gamesServices from "../../services/games.services";

const getMenuElements = () => [
    {
        url: 'games/all',
        title: 'All',
        translationKey: 'categories.menu.elements.all'
    },
    gamesServices.getTagDetail('popular'),
    gamesServices.getTagDetail('new-slots'),
    gamesServices.getTagDetail('slots'),
    gamesServices.getTagDetail('roulette'),
    gamesServices.getTagDetail('cards'),
    gamesServices.getTagDetail('jackpot'),
    gamesServices.getTagDetail('other'),
    {
        url: 'games/themes',
        title: 'Themes',
        translationKey: 'categories.menu.elements.themes',
    },
    {
        url: 'games/providers',
        title: 'Providers',
        translationKey: 'categories.menu.elements.providers'
    }
];

function CategoriesMenuElements(props) {
    const { t } = useTranslate()

    const {category} = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    let currentPath = location.pathname;
    currentPath = currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

    const getMenuElement = ({title, url, extraClasses, translationKey}) => {
        let link = `${helpersUtils.getLanguageLink(`/${url}`)}`;
        link = link.slice(-1) === '/' ? link.slice(0, -1) : link;

        return props.mobile ?
            (
                <li key={url} className={`mobile-menu__item ${extraClasses || ''}`}>
                    <Link onClick={() => dispatch(setShowMobileMenu(false))} to={link}  className={`nav-link nav-link--dark ${link === currentPath ? 'nav-link--current' : ''}`}>
                        <span className="nav-link__info">{t(translationKey,title)}</span>
                    </Link>
                </li>
            )
            :
            (
                <li key={url} className= {`them-filter__item ${extraClasses || ''}`}>
                    <Link onClick={() => dispatch(setShowMobileMenu(false))} to={link} className={`them-filter__link ${extraClasses || ''} ${category === url ? 'them-filter__link--active': ''}`}>
                        <span className="them-filter__link-info">{t(translationKey, title)}</span>
                    </Link>
                </li>
            )
    }

    return (
        <Fragment>
            {
                getMenuElements().map(element => {
                    return getMenuElement(element)
                })
            }
        </Fragment>
    );
}

export default CategoriesMenuElements;
