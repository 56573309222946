import {T} from "@tolgee/react";

import promo1 from '../assets/img/promo/01.jpg';

function Tournaments() {
    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>турниры</h2>
                        </div>
                    </div>
                    <div className="tournament">
                        <div className="tournament__grid">
                            <div className="tournament-card tournament-card--vertical">
                                <div className="tournament-card__img-box">
                                    <img src={promo1} alt="" className="tournament-card__img"/>
                                </div>
                                <div className="tournament-card__content">
                                    <div className="tournament-card__info-box">
                                        <div className="countdown">
                                            <div className="countdown__item">
                                                <div className="countdown__count">03</div>
                                                <div className="countdown__name"><T keyName="tournaments.day">day</T></div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">23</div>
                                                <div className="countdown__name"><T keyName="tournaments.hour">day</T></div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">09</div>
                                                <div className="countdown__name"><T keyName="tournaments.min">min</T></div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">37</div>
                                                <div className="countdown__name"><T keyName="tournaments.sec">sec</T></div>
                                            </div>
                                        </div>
                                        <div className="tournament-card__info tournament-card__info--big">
                                            <T keyName="tournaments.every_day_tournament">Every day tournament</T>
                                            <br/>
                                            1000 EUR
                                        </div>
                                    </div>
                                    <div className="tournament-card__btn-row">
                                        <div className="btn btn--medium"><span><T keyName="tournaments.particiapte">Participate</T></span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="tournament__table-box">
                                <h3 className="ttu text-center"><T keyName="tournaments.tournament_table">Tournament table</T></h3>
                                <div className="tournament__table-row">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td className="decor-bg"><b>1</b></td>
                                                <td className="decor-bg">Alex89</td>
                                                <td className="decor-bg text-right"><b>75 898</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>2</b></td>
                                                <td className="decor-bg">Nroneton</td>
                                                <td className="decor-bg text-right"><b>57 322</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>3</b></td>
                                                <td className="decor-bg">Alex89</td>
                                                <td className="decor-bg text-right"><b>12 887</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>4</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>9 687</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>5</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>8 012</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>6</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>7 586</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>7</b></td>
                                                <td>Nroneton</td>
                                                <td className="text-right"><b>6 854</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>8</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>5 486</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>9</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>4 356</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>10</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>3 561</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><b>11</b></td>
                                                <td>Alex89</td>
                                                <td className="text-right"><b>2 351</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>12</b></td>
                                                <td>Nroneton</td>
                                                <td className="text-right"><b>1 976</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>13</b></td>
                                                <td>Alex89</td>
                                                <td className="text-right"><b>849</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>14</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>783</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>15</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>621</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>16</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>597</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>17</b></td>
                                                <td>Nroneton</td>
                                                <td className="text-right"><b>485</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>18</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>347</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>19</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>157</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>20</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>96</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center">
                                    <a href="#" className="tournament__table-prev-link"><T keyName="tournaments.previous_winners">Previous winners</T></a>
                                </div>
                            </div>
                            <div className="tournament__info-box">
                                <h3 className="ttu text-center"><T keyName="tournaments.information">Information</T>:</h3>
                                <div className="tournament__info-main">
                                    <T keyName="tournaments.first_three_winners_prizes">The first three winners will receive valuable prizes</T>:
                                </div>
                                <table className="tournament__table">
                                    <tbody>
                                        <tr>
                                            <td>1 место:</td>
                                            <td><b>Смартфон Apple Iphone 14 PRO</b></td>
                                        </tr>
                                        <tr>
                                            <td>2 место:</td>
                                            <td><b>Наручные смарт часы Apple Watch</b></td>
                                        </tr>
                                        <tr>
                                            <td>3 место:</td>
                                            <td><b>Безпроводные наушники Apple IPod</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tournament__info">Остальные игроки получат бонусы в зависимости от общей
                                    суммы ставок.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tournament">
                        <div className="tournament__grid">
                            <div className="tournament-card tournament-card--vertical">
                                <div className="tournament-card__img-box">
                                    <img src={promo1} alt="" className="tournament-card__img"/>
                                </div>
                                <div className="tournament-card__content">
                                    <div className="tournament-card__info-box">
                                        <div className="countdown">
                                            <div className="countdown__item">
                                                <div className="countdown__count">03</div>
                                                <div className="countdown__name">день</div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">23</div>
                                                <div className="countdown__name">час</div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">09</div>
                                                <div className="countdown__name">мин</div>
                                            </div>
                                            <div className="countdown__item">
                                                <div className="countdown__count">37</div>
                                                <div className="countdown__name">сек</div>
                                            </div>
                                        </div>
                                        <div className="tournament-card__info tournament-card__info--big">
                                            Еженедельный турнир:
                                            <br/>
                                            100 000 рублей
                                        </div>
                                    </div>
                                    <div className="tournament-card__btn-row">
                                        <div className="btn btn--medium"><span>Участвовать</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="tournament__table-box">
                                <h3 className="ttu text-center">Турнирная таблица:</h3>
                                <div className="tournament__table-row">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td className="decor-bg"><b>1</b></td>
                                                <td className="decor-bg">Alex89</td>
                                                <td className="decor-bg text-right"><b>75 898</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>2</b></td>
                                                <td className="decor-bg">Nroneton</td>
                                                <td className="decor-bg text-right"><b>57 322</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>3</b></td>
                                                <td className="decor-bg">Alex89</td>
                                                <td className="decor-bg text-right"><b>12 887</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>4</b></td>
                                                <td className="decor-bg">Drego222</td>
                                                <td className="decor-bg text-right"><b>9 687</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>5</b></td>
                                                <td className="decor-bg">Adonanika</td>
                                                <td className="decor-bg text-right"><b>8 012</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>6</b></td>
                                                <td className="decor-bg">Proto7</td>
                                                <td className="decor-bg text-right"><b>7 586</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>7</b></td>
                                                <td className="decor-bg">Nroneton</td>
                                                <td className="decor-bg text-right"><b>6 854</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>8</b></td>
                                                <td className="decor-bg">Drego222</td>
                                                <td className="decor-bg text-right"><b>5 486</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>9</b></td>
                                                <td className="decor-bg">Adonanika</td>
                                                <td className="decor-bg text-right"><b>4 356</b></td>
                                            </tr>
                                            <tr>
                                                <td className="decor-bg"><b>10</b></td>
                                                <td className="decor-bg">Proto7</td>
                                                <td className="decor-bg text-right"><b>3 561</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td><b>11</b></td>
                                                <td>Alex89</td>
                                                <td className="text-right"><b>2 351</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>12</b></td>
                                                <td>Nroneton</td>
                                                <td className="text-right"><b>1 976</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>13</b></td>
                                                <td>Alex89</td>
                                                <td className="text-right"><b>849</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>14</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>783</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>15</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>621</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>16</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>597</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>17</b></td>
                                                <td>Nroneton</td>
                                                <td className="text-right"><b>485</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>18</b></td>
                                                <td>Drego222</td>
                                                <td className="text-right"><b>347</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>19</b></td>
                                                <td>Adonanika</td>
                                                <td className="text-right"><b>157</b></td>
                                            </tr>
                                            <tr>
                                                <td><b>20</b></td>
                                                <td>Proto7</td>
                                                <td className="text-right"><b>96</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center">
                                    <a href="#" className="tournament__table-prev-link">Предыдущие победители</a>
                                </div>
                            </div>
                            <div className="tournament__info-box">
                                <h3 className="ttu text-center">Информация:</h3>
                                <div className="tournament__info-main">
                                    Первой десятки победителей переведут на личный
                                    счёт игровые бонусы в зависимости от общей
                                    суммы ставок.
                                </div>
                                <table className="tournament__table">
                                    <tbody>
                                        <tr>
                                            <td>1:</td>
                                            <td><b>10 000</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>2:</td>
                                            <td><b>5 000</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>3:</td>
                                            <td><b>2 500</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>4:</td>
                                            <td><b>1 000</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>5:</td>
                                            <td><b>500</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>6:</td>
                                            <td><b>250</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>7:</td>
                                            <td><b>100</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>8:</td>
                                            <td><b>50</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>9:</td>
                                            <td><b>25</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                        <tr>
                                            <td>10:</td>
                                            <td><b>10</b><T keyName="tournaments.points">points</T></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Tournaments;