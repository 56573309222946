import Slider from 'react-slick';

import {s3Config} from '../../config/aws.config';

const imgUrl = `${s3Config.assetsUrl}/img`;

function PartnersSlider() {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        className: 'footer-partners__slider'
    };
    return (
        <div className="footer__partners">
            <Slider {...settings}>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/1.png`} alt=""
                                 className="footer-partners__link-img"/>
                            {/*<span class="footer-partners__link-info"></span>*/}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/2.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/3.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/4.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/5.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/6.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/7.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/8.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/9.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/10.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="footer-partners__item">
                        <div className="footer-partners__link">
                            <img src={`${imgUrl}/partners/11.png`} alt=""
                                 className="footer-partners__link-img"/>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default PartnersSlider;