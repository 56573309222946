import React, { useEffect, useState } from 'react';
import {T, useTranslate} from "@tolgee/react";

import { s3Config } from '../../config/aws.config';
import SmallButton from '../../components/ui/SmallButton';


const imgUrl = `${s3Config.assetsUrl}/img`;

// Placeholder Arrs
const documentTypesArr = [
    {
        value: 1,
        type: 'Passport',
        translationKey: 'account.verification.passport'
    },
    {
        value: 2,
        type: 'Driver License',
        translationKey: 'account.verification.driver_license'
    },
    {
        value: 3,
        type: 'Driver License 2',
        translationKey: 'account.verification.driver_license_2'
    },
    {
        value: 4,
        type: 'Selfie',
        translationKey: 'account.verification.selfie'
    },
    {
        value: 5,
        type: 'Bank Statement',
        translationKey: 'account.verification.bank_statement'
    },
    {
        value: 6,
        type: 'Utility Bill',
        translationKey: 'account.verification.utility_bill'
    },
    {
        value: 7,
        type: 'ID Card',
        translationKey: 'account.verification.id_card'
    },
    {
        value: 8,
        type: 'Other',
        translationKey: 'account.verification.other'
    },
    {
        value: 9,
        type: 'E-Wallet',
        translationKey: 'account.verification.e_wallet'
    },
];
const downloadDocumentsArr = [
];
// Placeholder Dataformat
// 'ru-RU'
const formattedDate = (timestampValue, locale) => {
    const lastModifiedDate = new Date(timestampValue);
    const timeString = lastModifiedDate.toLocaleTimeString(locale, { hour12: false });
    const dateString = lastModifiedDate.toLocaleDateString(locale);
    const formattedDate = `${timeString} / ${dateString}`;

    return formattedDate
}


const AccountVerification = () => {
    const { t } = useTranslate()
    const [isVerified,] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [documentTypeLabel, setDocumentTypeLabel] = useState(null);
    const [documentTypeTranslation, setDocumentTypeTranslation] = useState(null);
    const [dropdownActive, setDropdownActive] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        // Placeholder push 
        downloadDocumentsArr.push(event.target.files[0]);
    };

    const handleDocumentTypeChange = (el) => {
        setDocumentType(el.value);
        setDocumentTypeLabel(el.type);
        setDocumentTypeTranslation(el.translationKey);
        setDropdownActive(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Placeholder data
        if (selectedFile && documentType) {
            setIsDownload(true);
        }

        // Ready to real fetch
        if (selectedFile && documentType) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('documentType', documentType);
            formData.append('documentTypeLabel', documentTypeLabel);

            fetch('/upload', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Upload success:', data);
                    // Do something with the response data
                })
                .catch(error => {
                    console.error('Upload error:', error);
                    // Handle the upload error
                });
        }
    };

    useEffect(() => {

    }, [])


    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName="account.verification.verification">Verification</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            {
                                isVerified ? (
                                    <div className="account__body">
                                        <div className="account__shape">
                                            <div className="allert-message allert-message--center">
                                                <img src={`${imgUrl}/icons/icons.svg#ico_verify`} className="allert-message__img" alt="ico_verify" />
                                                <span><T keyName="account.verification.user_verified">User verified</T></span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="account__body">
                                        <div className="account__shape account__shape--large">
                                            <form className="form account__form" onSubmit={handleSubmit}>
                                                <fieldset>
                                                    <div className="account__form-row">
                                                        <div className="input-area" data-label={t('account.verification.document_type', 'Document type')} data-error={t('account.verification.document_type', 'Document type')}>
                                                            <div className={`input-drop-down ${dropdownActive ? 'input-drop-down--active' : null}`}>
                                                                <div className="input-drop-down__current" onClick={() => { setDropdownActive(true) }}>
                                                                        {documentTypeLabel ? t(documentTypeTranslation, documentTypeLabel) : t('account.verification.document_type', 'Document type')}
                                                                </div>
                                                                <ul className="input-drop-down__list">
                                                                    {
                                                                        documentTypesArr.map(el => {
                                                                            return (
                                                                                <li key={el.value} className="input-drop-down__item" onClick={() => handleDocumentTypeChange(el)}>
                                                                                    <label className="input-drop-down__label">
                                                                                        <input type="radio" name="documentType" value={el.value} className="input-drop-down__radio" />
                                                                                        <span className="input-drop-down__content">
                                                                                            <span className="input-drop-down__info">{t(el.translationKey, el.type)}</span>
                                                                                        </span>
                                                                                    </label>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <label className="input-area input-area--copy" data-label={t('account.verification.select_file', 'Please select a file')} data-error={t('account.verification.select_file', 'Please select a file')}>
                                                            <input
                                                                type="file"
                                                                className="input-area__input"
                                                                placeholder={t('account.verification.select_file', 'Please select a file')}
                                                                onChange={handleFileChange}
                                                                accept="image/*,.png,.jpg,.gif,"
                                                            />
                                                            <div className="input-area__placeholder">{t('account.verification.select_file', 'Please select a file')}</div>
                                                            <div className="input-area__img-box">
                                                                <img src={`${imgUrl}/icons/icons.svg#ico_browse`} className="svg-ico_browse-dims" alt="ico_browse" />
                                                            </div>
                                                        </label>
                                                        <button type="submit" className="btn btn--blue" disabled={!selectedFile || !documentType}>
                                                            <span><T keyName="account.verification.download">Download</T></span>
                                                        </button>
                                                    </div>
                                                </fieldset>
                                            </form>
                                                {isDownload && <div className="table-container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th><T keyName="account.verification.document_type">Document type</T></th>
                                                            <th><T keyName="account.verification.download_type">Download time</T></th>
                                                            <th><T keyName="account.verification.file_name">File name</T></th>
                                                            <th><T keyName="account.verification.status">Status</T></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            downloadDocumentsArr.map((document, id) => {
                                                                return (
                                                                    <tr key={`${document.lastModified}_${id}`}>
                                                                        <td>{documentTypeLabel}</td>
                                                                        <td>{formattedDate(document.lastModified, 'ru-RU' )}</td>
                                                                        <td><span className="decor-color">{document.name}</span></td>
                                                                        <td><T keyName="account.verification.download">Download</T></td>
                                                                        <td>
                                                                            <SmallButton black>
                                                                                <span><T keyName="account.verification.show">Show</T></span>
                                                                            </SmallButton>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>}
                                            <p><T keyName="account.verification.upload_identification_document">Dear user, to verify your account you need to upload an identification document.</T></p>
                                            <p className="bold"><T keyName="account.verification.upload_criteria">When uploading, make sure it meets the following criteria:</T></p>
                                            <ul className="dot-list">
                                                <li><T keyName="account.verification.upload_criteria_1">The file is in JPG, PNG or GIF format and does not exceed 15 MB.</T></li>
                                                <li><T keyName="account.verification.upload_criteria_2">The document has an expiration date.</T></li>
                                                <li><T keyName="account.verification.upload_criteria_3">The picture must be in real colors, not black and white.</T></li>
                                                <li><T keyName="account.verification.upload_criteria_4">The photograph must be taken from the original document; digital photo editing is not permitted.</T></li>
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AccountVerification;