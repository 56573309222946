import {useState} from 'react';
import {T, useTranslate} from "@tolgee/react";

import helpersUtils from '../../utils/helpers.utils';
import useRootHook from '../../context/useRoot';

function ForgotPopup() {
    const { t } = useTranslate()
    const {setPopup} = useRootHook();
    const defaultFields = {remember: true};
    const [fields, setFields] = useState(defaultFields);

    const handleFocus = (event) => {

    };
    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = {...fields};

        newFields[name] = target.dataset.lowercase === 'true' ? value.toLowerCase() : value;

        setFields(newFields);
    };
    const handleBlur = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = {...fields};
        newFields[name] = target.dataset.trim === 'true' ? value.trim() : value;
        setFields(newFields);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(fields);
    };

    const socialClick = (ev) => {
        ev.preventDefault();
        alert('Social login unavailable');
    };

    const openForgotPassword = () => {

    };

    return (
        <div className="popup__content">
            <div className="form-container">
                <div className="heading">
                    <div className="heading__content">
                        <h2><T keyName="forgot.popup.recover_password">Recover password</T></h2>
                    </div>
                </div>
                <form className="form">
                    <fieldset>
                        <label className="input-area" data-label={t('forgot.popup.login', "Login")} data-error={t('forgot.popup.login', "Введите ваш логин")}>
                            <input type="text" className="input-area__input" placeholder={t('forgot.popup.login', "Login")}/>
                        </label>
                        <label className="input-area" data-label={t('forgot.popup.email_or_phone_number', "Email or phone number")}
                               data-error={t('forgot.popup.email_or_phone_number', "Email or phone number")}>
                            <input type="text" className="input-area__input" placeholder={t('forgot.popup.email_or_phone_number', "Email or phone number")}/>
                        </label>
                        <br/>
                        <div><T keyName="forgot.popup.recover_password_info">Recovery code will be send on your email or phone number</T></div>
                        <div className="form__row">
                            <label className="checkbox">
                                <input type="checkbox"/>
                                <div className="checkbox__label-content">
                                    <div className="checkbox__square"></div>
                                    <b><T keyName="forgot.popup.not_robot">I am not robot</T></b>
                                </div>
                            </label>
                        </div>
                        <div className="btn-row">
                            <label className="btn btn--medium" id="recovery-popup-trigger-2">
                                <input type="submit"/>
                                <span><T keyName="forgot.popup.send">Send</T></span>
                            </label>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}

export default ForgotPopup;