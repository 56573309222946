import requestUtils from '../utils/request.utils';
import usersServices from './users.services';

const playTransactionsServices = {};

playTransactionsServices.index = async (filters) => {
    try {
        return requestUtils.get('/playtransactions', filters, true);
    } catch (err) {
        console.error(err);
    }
};

export default playTransactionsServices;