import React, { useEffect, useState } from 'react';
import {T, useTranslate} from "@tolgee/react";

import gamesServices from '../../services/games.services';
import Game from '../../components/Game';
import { s3Config } from '../../config/aws.config';

const imgUrl = `${s3Config.assetsUrl}/img`;
const BATCH_SIZE = 48;

const AccountFavoriteGames = (props) => {
    const { t } = useTranslate()

    const [games, setGames] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [endSize, setEndSize] = useState(BATCH_SIZE);

    useEffect(() => {
        gamesServices.getFavorites(0, endSize).then((data) => {
            setGames(data);
        });
    }, [endSize, refresh]);

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading heading--between">
                        <div className="heading__content">
                            <h2><T keyName="account.favorite.games.favorite_games_title">Favorite games</T></h2>
                        </div>
                        <div
                            onClick={() => {
                                if (window.confirm(t('account.favorite.games.delete_all_confirmation_message', 'Are you sure you want to clear all favorite games'))) {
                                    gamesServices.deleteAllFavorites();
                                    setGames({});
                                    setEndSize(BATCH_SIZE);
                                }
                            }}
                            className="small-btn small-btn--black"
                        >
                            <span><T keyName="account.favorite.games.delete_all">Delete all</T></span>
                        </div>
                    </div>
                    <div className="account">
                        <div className={`game-grid `}>
                            {
                                games?.data?.map((game) => {
                                    return (
                                        <div className="account__favorite-game">
                                            <div className="account__favorite-remove">
                                                <div
                                                    className="btn-close"
                                                    onClick={() => {
                                                        gamesServices.toggleFavorite(game.id);
                                                        setRefresh(refresh => ++refresh)
                                                    }}
                                                >
                                                    <img src={`${imgUrl}/icons/icons.svg#ico_close`} alt="" className="btn-close__img" />
                                                </div>
                                            </div>
                                            <Game key={game.id} game={game} hideFavourite={true}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="heading">
                            <div className="heading__line"></div>
                            <div className="heading__content">
                                {
                                    games.hasMore && (
                                        <div className="btn-row">
                                            <div
                                                className="btn btn--black"
                                                onClick={() => {
                                                    setEndSize(end => end + BATCH_SIZE);
                                                }}
                                            >
                                                <span><T keyName="pagination.show_more">Show more</T></span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="heading__line"></div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AccountFavoriteGames;