import {isMobile} from 'react-device-detect';

import requestUtils from '../utils/request.utils';

const allGamesCacheKey = 'games';
const allIDsCacheKey = 'game_ids';
const allGameIDsCacheKey = 'game_gids';
const favoritesCacheKey = 'favorites';
let allGames;
let loadingRemoteData;
let firstCall = true;

const TAGS_LIST = [
    {
        url: 'games/themes/books',
        name: 'books',
        title: 'Books',
        translationKey: 'games.tags.books',
        showOn: ['theme']
    },
    {
        url: 'games/themes/asian',
        name: 'asian',
        title: 'Asian',
        translationKey: 'games.tags.asian',
        showOn: ['theme']
    },
    {
        url: 'games/themes/animals',
        name: 'animals',
        title: 'Animals',
        translationKey: 'games.tags.animals',
        showOn: ['theme']
    },
    {
        url: 'games/themes/summertime',
        name: 'summertime',
        title: 'Summertime',
        translationKey: 'games.tags.summertime',
        showOn: ['theme']
    },
    {
        url: 'games/themes/horror',
        name: 'horror',
        title: 'Horror',
        translationKey: 'games.tags.horror',
        showOn: ['theme']
    },
    {
        url: 'games/themes/holidays',
        name: 'holidays',
        title: 'Holidays',
        translationKey: 'games.tags.holidays',
        showOn: ['theme']
    },
    {
        url: 'games/themes/movies-and-tv-shows',
        name: 'movies-and-tv-shows',
        title: 'Movie and TV shows',
        translationKey: 'games.tags.movie_and_tv_shows',
        showOn: ['theme']
    },
    {
        url: 'games/themes/adventures',
        name: 'adventures',
        title: 'Adventures',
        translationKey: 'games.tags.adventures',
        showOn: ['theme']
    },
    {
        url: 'games/themes/fruits',
        name: 'fruits',
        title: 'Fruits',
        translationKey: 'games.tags.fruits',
        showOn: ['theme']
    },
    {
        url: 'games/themes/romantic',
        name: 'romantic',
        title: 'Romantic',
        translationKey: 'games.tags.romantic',
        showOn: ['theme']
    },
    {
        url: 'games/themes/myth-and-history',
        name: 'myth-and-history',
        title: 'Myth and History',
        translationKey: 'games.tags.myth_and_history',
        showOn: ['theme']
    },
    {
        url: 'games/themes/sports',
        name: 'sports',
        title: 'Sports',
        translationKey: 'games.tags.sports',
        showOn: ['theme']
    },
    {
        url: 'games/themes/fantasy',
        name: 'fantasy',
        title: 'Fantasy',
        translationKey: 'games.tags.fantasy',
        showOn: ['theme']
    },
    {
        url: 'games/themes/casual',
        name: 'casual',
        title: 'Casual',
        translationKey: 'games.tags.casual',
        showOn: ['theme']
    },
    {
        url: 'games/popular',
        name: 'popular',
        title: 'Popular',
        translationKey: 'games.tags.popular',
        showOn: ['menu']
    },
    {
        url: 'games/new-slots',
        name: 'new-slots',
        title: 'New',
        translationKey: 'games.tags.new',
        showOn: ['menu']
    },
    {
        url: 'games/slots',
        name: 'slots',
        title: 'Slots',
        translationKey: 'games.tags.slots',
        showOn: ['menu']
    },
    {
        url: 'games/roulette',
        name: 'roulette',
        title: 'Roulette',
        translationKey: 'games.tags.roulette',
        showOn: ['menu']
    },
    {
        url: 'games/cards',
        name: 'cards',
        title: 'Cards',
        translationKey: 'games.tags.cards',
        showOn: ['menu']
    },
    {
        url: 'games/jackpot',
        name: 'jackpot',
        title: 'Jackpot',
        translationKey: 'games.tags.jackpot',
        showOn: ['menu']
    },
    {
        url: 'games/other',
        name: 'other',
        title: 'Other',
        translationKey: 'games.tags.other',
        showOn: ['menu']
    },
    {
        url: 'games/tv-games',
        name: 'tv-games',
        title: 'TV games',
        translationKey: 'games.tags.tv_games',
        showOn: ['menu-elements']
    },
    {
        url: 'games/virtual-games',
        name: 'virtual-games',
        title: 'Virtual',
        translationKey: 'games.tags.virtual_games',
        showOn: ['menu-elements']
    },
    {
        url: 'games/live-games',
        name: 'live-games',
        title: 'Live casino',
        translationKey: 'games.tags.live_games',
        showOn: ['menu-elements', 'home-page']
    },
    {
        url: 'games/instant-win',
        name: 'instant-win',
        title: 'Instant win',
        translationKey: 'games.tags.instant_win',
        showOn: ['home-page']
    },
    {
        url: 'games/bonus-buy',
        name: 'bonus-buy',
        title: 'Bonus buy',
        translationKey: 'games.tags.bonus_buy',
        showOn: ['home-page']
    }
]

const allCacheKeysKey = 'all-keys';
const addCacheKey = (key) => {
    const allCacheKeys = JSON.parse(localStorage.getItem(allCacheKeysKey)) || [];
    !allCacheKeys.includes(key) && allCacheKeys.push(key);
    localStorage.setItem(allCacheKeysKey, JSON.stringify(allCacheKeys));
};
const clearAllCacheKeys = () => {
    const allCacheKeys = JSON.parse(localStorage.getItem(allCacheKeysKey)) || [];
    allCacheKeys.forEach((key) => {
        localStorage.removeItem(key);
    });
    localStorage.setItem(allCacheKeysKey, JSON.stringify([]));
};
const setCacheKey = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
    addCacheKey(key);
}
const getRemoteGames = async () => {
    clearAllCacheKeys();

    allGames = await requestUtils.get('/games', {is_mobile: isMobile});

    const providers = {};
    const allGameIDs = [];
    const allIDs = allGames.map(game => {
        if (!providers[game.gameprovider_id]) {
            providers[game.gameprovider_id] = [];
        }
        providers[game.gameprovider_id].push(game.id);
        allGameIDs.push(game.game_id);
        return game.id;
    });

    setCacheKey(allIDsCacheKey, allIDs);
    setCacheKey(allGameIDsCacheKey, allGameIDs);
    //setCacheKey(allGamesCacheKey, allGames);

    Object.keys(providers).forEach(providerID => {
        setCacheKey(gamesServices.providerCacheKey(providerID), providers[providerID]);
    });
};

const gamesServices = {};

gamesServices.getGame = async (id) => {
    return requestUtils.get(`/games/${id}`);
}

gamesServices.getGames = async (forceRemote = false) => {
    if (firstCall) {
        loadingRemoteData = true;
        firstCall = false;
    } else {
        await checkLoadingRemoteData();
    }

    if (!forceRemote && allGames) {
        loadingRemoteData = false;
        return allGames;
    }

    /*allGames = localStorage.getItem(allGamesCacheKey);
    allGames = allGames ? JSON.parse(allGames) : allGames;

    if (!forceRemote && allGames) {
        loadingRemoteData = false;
        return allGames;
    }*/

    await getRemoteGames();

    loadingRemoteData = false;
    return allGames;
};


gamesServices.providerCacheKey = (providerId) => {
    return `games-provider-${providerId}`;
}
gamesServices.providersDetailsCacheKey = () => 'games-providers-details';
gamesServices.getProviders = async () => {
    const providersDetailsKey = gamesServices.providersDetailsCacheKey();
    const providers = localStorage.getItem(providersDetailsKey);
    if (providers) {
        return JSON.parse(providers);
    }
    const providersDetails = await requestUtils.get('/games/providers', {}, true);
    setCacheKey(providersDetailsKey, providersDetails);
    return providersDetails;
};
gamesServices.getProvider = async (providerID) => {
    const providersDetails = await gamesServices.getProviders();
    return providersDetails.find(provider => provider.gameprovider_id === providerID);
}

gamesServices.getProviderGameCount = (providerId) => {
    return JSON.parse(localStorage.getItem(gamesServices.providerCacheKey(providerId)) || '[]').length;
};

gamesServices.getByProvider = async (providerID, start = 0, length = 12) => {
    try {
        let gameKey = gamesServices.providerCacheKey(providerID);

        let gamesByProvider = JSON.parse(localStorage.getItem(gameKey) || '[]');

        if (gamesByProvider) {
            return paginateGames(gamesByProvider, start, length, true);
        }
    } catch (err) {
        return Promise.reject(err);
    }
};


gamesServices.getAllGames = async (start = 0, length = 12) => {
    try {
        await gamesServices.getGames();
        return paginateGames(allGames, start, length, false);
    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.gameTagCacheKey = (tagID) => {
    return `games-tag-${tagID}`;
}
gamesServices.getByTag = async (tagName, start = 0, length = 12) => {
    try {
        const cacheKey = gamesServices.gameTagCacheKey(tagName)
        const gamesByTag = JSON.parse(localStorage.getItem(cacheKey));

        if (gamesByTag) {
            return paginateGames(gamesByTag, start, length, true);
        }

        const tagGames = [];
        for (let i = 0; i < allGames.length; i++) {
            if (allGames[i].gametags.indexOf(tagName) === -1) {
                continue;
            }
            tagGames.push(allGames[i].id);
        }

        setCacheKey(cacheKey, tagGames);
        return paginateGames(tagGames, start, length, true);

    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.tagsDetailsCacheKey = () => 'games-tags-details';
gamesServices.getTags = async () => {
    const tagsDetailsKey = gamesServices.tagsDetailsCacheKey();
    const tags = localStorage.getItem(tagsDetailsKey);
    if (tags) {
        return JSON.parse(tags);
    }
    const tagsDetails = await requestUtils.get('/games/tags', {}, true);
    setCacheKey(tagsDetailsKey, tagsDetails);
    return tagsDetails;
};
gamesServices.getThemeTagsDetails = () => {
    return TAGS_LIST.filter((item) => item.showOn.includes('theme'));
};
gamesServices.getCategoriesMenuTagsDetails = () => {
    return TAGS_LIST.filter((item) => item.showOn.includes('menu'));
};
gamesServices.getMenuElementsTagsDetails = () => {
    return TAGS_LIST.filter((item) => item.showOn.includes('menu-elements'));
};
gamesServices.getTagDetail = (value) => {
    return TAGS_LIST.find((item) => value === item.name);
};

gamesServices.getByIds = async (arrayId) => {
    try {
        const allIDs = JSON.parse(localStorage.getItem(allIDsCacheKey) || '[]');
        const gamesArray = arrayId.map((id) => {
            return allGames[allIDs.indexOf(id)];
        });

        return Promise.resolve(gamesArray);
    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.getById = async (id) => {
    try {
        const allIDs = JSON.parse(localStorage.getItem(allIDsCacheKey) || '[]');
        return allGames[allIDs.indexOf(id)];
    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.getByGameId = (gameId) => {
    try {
        const allGameIDs = JSON.parse(localStorage.getItem(allGameIDsCacheKey) || '[]');
        return allGames[allGameIDs.indexOf(gameId)];
    } catch (err) {
        console.error(err)
    }
};

gamesServices.getByGameIds = async (arrayId) => {
    try {
        const allGameIDs = JSON.parse(localStorage.getItem(allGameIDsCacheKey) || '[]');
        const gamesArray = arrayId.map((gameId) => {
            return allGames[allGameIDs.indexOf(gameId)];
        });

        return Promise.resolve(gamesArray);
    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.search = async (name, start = 0, length = 20) => {
    try {
        name = name.toLowerCase();

        let foundGames = [];
        allGames.every((game, index) => {
            if (!name || game.name.toLowerCase().includes(name)) {
                foundGames.push(game.id);//optimization: push only ids into the array, saving memory. pagination will later find only the pagination amount of games
            }
            return foundGames.length !== start + length;
        })

        return paginateGames(foundGames, start, length, true);
    } catch (err) {
        return Promise.reject(err);
    }
};

gamesServices.getSlug = (name, id) => {
    return `${name.toLowerCase().replace(/ /g, '-')}-${id}`;
}

gamesServices.getImgUrl = (fileName) => {
    //return `https://d32wwkuryjkg7f.cloudfront.net/img/${fileName}`;
    return fileName
};
gamesServices.getBgUrl = (fileName) => {
    return `https://d32wwkuryjkg7f.cloudfront.net/bg/${fileName}`;
};
gamesServices.getPlayLink = (slug, language) => {
    return `/${language}/games/play/${slug}`;
};
gamesServices.getDemoLink = (slug, language) => {
    return `/${language}/games/demo/${slug}`;
};
gamesServices.launch = async (game, locale, isMobile) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const reqData = {
        game: game,
        is_mobile: isMobile,
        locale: locale,
        return_url: `${protocol}//${host}/${locale}`,
        deposit_url: `${protocol}//${host}/${locale}/account/deposit`,
        play_history_url: `${protocol}//${host}/${locale}/account/play-history`,
    };

    return requestUtils.post('/games/launch', reqData, true)
};

gamesServices.launchDemo = async (game, locale, isMobile) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const reqData = {
        game: game,
        is_mobile: isMobile,
        locale: locale,
        return_url: `${protocol}//${host}/${locale}`
    };

    return requestUtils.post('/games/launch-demo', reqData, true)
};

gamesServices.getFavorites = async (start = 0, length = 48) => {
    const favs = gamesServices.getAllFavorites();
    return paginateGames(favs, start, length, true);
}

gamesServices.getAllFavorites = () => {
    return JSON.parse(localStorage.getItem(favoritesCacheKey) || '[]');
};

gamesServices.isFavorite = (gameId) => {
    return gamesServices.getAllFavorites().includes(gameId);
}

gamesServices.toggleFavorite = (gameId) => {
    const favorites = gamesServices.getAllFavorites();
    favorites.includes(gameId) ? favorites.splice(favorites.indexOf(gameId), 1) : favorites.push(gameId);
    localStorage.setItem(favoritesCacheKey, JSON.stringify(favorites));
};
gamesServices.deleteAllFavorites = () => {
    localStorage.setItem(favoritesCacheKey, '[]');
};

export default gamesServices;

const paginateGames = async (gamesArray, start, length, onlyIds = true) => {
    try {
        length = gamesArray.length >= length ? length : gamesArray.length;
        let data = gamesArray.slice(start, length);

        if (!onlyIds) {
            return {data: gamesArray.slice(start, length), count: length, hasMore: length < gamesArray.length};
        }

        const paginatedData = await gamesServices.getByIds(data);

        return Promise.resolve({data: paginatedData, count: length, hasMore: length < gamesArray.length});
    } catch (err) {
        return Promise.reject(err);
    }
};

const checkLoadingRemoteData = () => {
    return new Promise((resolve, reject) => {
        if (!loadingRemoteData) {
            resolve();
        }
        let remoteDataInterval = setInterval(() => {
            if (!loadingRemoteData) {
                clearInterval(remoteDataInterval);
                resolve();
            }
        }, 50);
    });
};
