import {Helmet} from 'react-helmet';
import {s3Config} from '../config/aws.config';
const imgUrl = `${s3Config.assetsUrl}/img`;

function DefaultHelmet() {
    return (
        <Helmet>
            <link rel="apple-touch-icon" sizes="57x57" href={`${imgUrl}/favicon/apple-icon-57x57.png`}/>
            <link rel="apple-touch-icon" sizes="60x60" href={`${imgUrl}/favicon/apple-icon-60x60.png`}/>
            <link rel="apple-touch-icon" sizes="72x72" href={`${imgUrl}/favicon/apple-icon-72x72.png`}/>
            <link rel="apple-touch-icon" sizes="76x76" href={`${imgUrl}/favicon/apple-icon-76x76.png`}/>
            <link rel="apple-touch-icon" sizes="114x114" href={`${imgUrl}/favicon/apple-icon-114x114.png`}/>
            <link rel="apple-touch-icon" sizes="120x120" href={`${imgUrl}/favicon/apple-icon-120x120.png`}/>
            <link rel="apple-touch-icon" sizes="144x144" href={`${imgUrl}/favicon/apple-icon-144x144.png`}/>
            <link rel="apple-touch-icon" sizes="152x152" href={`${imgUrl}/favicon/apple-icon-152x152.pn`}/>
            <link rel="apple-touch-icon" sizes="180x180" href={`${imgUrl}/favicon/apple-icon-180x180.png`}/>
            <link rel="icon" type="image/png" sizes="192x192" href={`${imgUrl}/favicon/android-icon-192x192.png`}/>
            <link rel="icon" type="image/png" sizes="32x32" href={`${imgUrl}/favicon/favicon-32x32.png`}/>
            <link rel="icon" type="image/png" sizes="96x96" href={`${imgUrl}/favicon/favicon-96x96.png`}/>
            <link rel="icon" type="image/png" sizes="16x16" href={`${imgUrl}/favicon/favicon-16x16.png`}/>
        </Helmet>
    );
}

export default DefaultHelmet;