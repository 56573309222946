import {useDispatch} from 'react-redux';
import {T} from "@tolgee/react";

import {setPopup} from '../../redux/states.slice';

const ConfirmDepositPopup = (props) => {
    const dispatch = useDispatch();
    const {url} = props;

    const openNewWindow = async () => {
        window.open(url, '_blank', 'noopener,noreferrer');
        dispatch(setPopup(null));
    };

    return (
        <div className="popup__content">
            <div className="heading">
                <div className="heading__content">
                    <h2><T keyName="confirm.deposit.popup.deposit_confirmation">Deposit confirmation</T></h2>
                </div>
            </div>
            <div>
                <T keyName="confirm.deposit.popup.payment_information">The payment will continue in a new window</T>
            </div>
            <div className="btn-row">
                <div className="btn btn--small btn--black" onClick={openNewWindow}>
                    <span><T keyName="confirm.deposit.popup.ok">OK</T></span>
                </div>
                {/*<div className="btn btn--small" onClick={() => {
                    dispatch(setPopup(null));
                }}>
                    <span><T keyName="confirm.deposit.popup.no">No</T></span>
                </div>*/}
            </div>
        </div>
    );
};

export default ConfirmDepositPopup;