import requestUtils from '../utils/request.utils';
import helpersUtils from '../utils/helpers.utils';

const depositsServices = {};

depositsServices.index = async (filters) => {
    return requestUtils.get('/deposits', filters);
}

depositsServices.createDeposit = async (data) => {
    try {
        const protocol = window.location.protocol;
        const host = window.location.host;

        data.success_url = `${protocol}//${host}${helpersUtils.getLanguageLink('/')}`;
        data.fail_url = `${protocol}//${host}${helpersUtils.getLanguageLink('/')}`;
        return requestUtils.post('/deposits', data, true);
    } catch (err) {
        console.error(err);
    }
};
export default depositsServices;