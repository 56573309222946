import Slider from 'react-slick';
import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {T} from "@tolgee/react";

import helpersUtils from '../../utils/helpers.utils';
import gamesServices from '../../services/games.services';


export default function () {
    const [gameProviders, setGameProviders] = useState([]);
    const {provider} = useParams();

    const {name} = helpersUtils.splitNameAndID(provider || '');

    useEffect(() => {
        gamesServices.getProviders().then(data => {
            data && setGameProviders(data)
        });
    }, []);

    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        className: 'provider-catalog__slider'
    };

    return (
        <section className="section">
            <div className="wrapper">
                <div className="heading">
                    <div className="heading__content">
                        <h2><T keyName="providers.slider.providers_title">Providers</T></h2>
                    </div>
                </div>
                <div className="provider-catalog">
                    <Slider {...settings}>
                        {
                            gameProviders.map(gameProvider => {
                                const count = gamesServices.getProviderGameCount(gameProvider.gameprovider_id);
                                return count === 0 ? '' : (
                                    <div key={gameProvider.gameprovider_id}>
                                        <div className="provider-catalog__item" key={gameProvider.gameprovider_id}>
                                            <Link to={`${helpersUtils.getLanguageLink(`/games/providers/${gameProvider.name}-${gameProvider.gameprovider_id}`)}`} className={`provider-catalog__link ${name === gameProvider.name && 'provider-catalog__link--active'}`}>
                                            <span className="provider-catalog__link-img-box">
                                                        <img src={gameProvider.image1} alt={gameProvider.title}
                                                             className="provider-catalog__link-img"/>
                                                    </span>
                                                <span className="provider-catalog__link-info">{gameProvider.title}</span>
                                                <span className="provider-catalog__link-count">{count}</span>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </section>
    );
}