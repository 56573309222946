import requestUtils from '../utils/request.utils';

const countriesServices = {}

countriesServices.index = async () => {
    try {
        return requestUtils.get('/countries', {}, true);
    } catch (err) {
        console.error(err);
    }
}
export default countriesServices;