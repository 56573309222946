import {T, useTranslate} from "@tolgee/react";
import {useEffect, useState} from 'react';
import validator from 'validator/es';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {Tooltip} from 'react-tooltip';
import {useLocation, useNavigate} from 'react-router-dom';

import regPromo from '../../assets/img/promo/reg-promo.jpg';
import helpersUtils from '../../utils/helpers.utils';
import usersServices from '../../services/users.services';
import {setPopup} from '../../redux/states.slice';
import StyledSelect from '../ui/StyledSelect';
import {REGISTRATION_POPUP} from '../PopupContainer';
import projectsServices from '../../services/projects.services';


function RegistrationPopup() {
    const { t } = useTranslate()
    const defaultFields = {remember: true};
    const [fields, setFields] = useState(defaultFields);
    const [registrationConfig, setRegistrationConfig] = useState(null);
    const [availableCurrencies, setAvailableCurrencies] = useState([]);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        projectsServices.getAvailableCurrencies().then(data => {
            data && setAvailableCurrencies(data);
        });

        return () => {
            if (location.hash === `#${REGISTRATION_POPUP}`) {
                navigate('#')
            }
        }
    }, []);

    const handleChange = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);
        const newFields = {...fields};

        newFields[name] = target.dataset.lowercase === 'true' ? value.toLowerCase() : value;

        setFields(newFields);
        setErrors({...errors, [name]: undefined});
    };
    const handleBlur = (event) => {
        const [target, value, name] = helpersUtils.getFormEventData(event);

        const newFields = {...fields};
        const newErrors = {...errors};

        newFields[name] = target.dataset.trim === 'true' ? value.trim() : value;

        validatorFields[name] && validatorFields[name](newFields, newErrors, t);

        setErrors(newErrors);
        setFields(newFields);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFields = {...fields};

        const newErrors = validate(newFields);
        setErrors(newErrors);

        console.log(errors, Object.keys(newErrors).length);
        if (Object.keys(newErrors).length !== 0) return;

        const success = await usersServices.register(newFields);

        if (success) {
            toast(t('registration.popup.successful', 'Registration successful'));
            dispatch(setPopup(null));
        }
    };

    if (availableCurrencies.length == 0) {
        return null;
    }

    return (
        <div className="popup__content popup__content--no-padding">
            <div className="registration-popup">
                <form className="form" onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="tab">
                            {/*<div className="tab__menu ">
                                <div className="tab__menu-item tab__menu-item--active" data-tab="tab0">
                                    <div className="tab__step">1</div>
                                </div>
                                <div className="tab__menu-item" data-tab="tab1">
                                    <div className="tab__step">2</div>
                                </div>
                                <div className="tab__menu-item" data-tab="tab2">
                                    <div className="tab__step">
                                        <svg width="512" height="512" x="0" y="0"
                                             viewBox="0 0 24 24"
                                             className="tab__img">
											<g>
												<path xmlns="http://www.w3.org/2000/svg" clipRule="evenodd"
                                                      d="m20.6136 5.64877c.4199.36742.458 1.00751.0845 1.42204l-10.5139 11.66979c-.37544.4167-1.02006.4432-1.42843.0588l-6.08403-5.7276c-.37942-.3572-.41574-.9524-.09021-1.3593.3592-.449 1.02811-.5108 1.4556-.1263l4.72039 4.2459c.41022.369 1.04179.336 1.41138-.0737l9.0435-10.02691c.3659-.40576.99-.44254 1.4012-.08272z"
                                                      fill="#95a1b6" fillRule="evenodd" data-original="#000000"
                                                      className="tab__icon"></path>
											</g>
										</svg>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="tab__content">
                                <div data-tab="tab0">
                                    <div className="heading heading--center">
                                        <div className="heading__content">
                                            <h2>Регистрация</h2>
                                        </div>
                                    </div>
                                    <div className="registration-popup__row">
                                        <div className="registration-popup__item registration-popup__item--no-padding">
                                            <div className="registration-popup__alert-box">
                                                <img src={regPromo} alt=""
                                                     className="registration-popup__alert-img"/>
                                                <div className="registration-popup__alert-info">
                                                    <div className="bold ttu">
                                                        <T keyName="registration.popup.promo_title">Get it using promo code: 20 free spins in the game Sahara Night</T>
                                                    </div>
                                                    <div className="small">
                                                        <T keyName="registration.popup.promo_offer">After the first deposit the code will be sent to your email</T>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="registration-popup__item">
                                            <div className="form-container">
                                                <StyledSelect
                                                    options={availableCurrencies.map(currency => ({
                                                        value: currency.id,
                                                        label: currency.code
                                                    }))}
                                                    title={t('registration.popup.currency', 'Currency')}
                                                    fieldName="currency_id"
                                                    error={errors.currency_id}
                                                    selectedValue={fields.currency_id}
                                                    setValue={(value) => {
                                                        setFields({...fields, currency_id: value});
                                                        setErrors({...errors, currency_id: undefined});
                                                    }}
                                                />
                                                <label
                                                    className={`input-area ${errors.email ? 'input-area--error' : ''}`}
                                                    data-label={`${t('registration.popup.email', "Email")}:`}
                                                    data-error={errors.email || ''}>
                                                    <input
                                                        name="email"
                                                        autoComplete="username"
                                                        placeholder={t('registration.popup.email', "Email")}
                                                        required
                                                        autoFocus
                                                        type="text"
                                                        className="input-area__input"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={fields.email || ''}
                                                        data-lowercase={true}
                                                        data-trim={true}
                                                        tabIndex={1}
                                                    />
                                                </label>
                                                <label
                                                    className={`input-area ${errors.password ? 'input-area--error' : ''}`}
                                                    data-tooltip-id="password-requirements"
                                                    data-tooltip-html={t('registration.popup.password_tooltip', "Password requiremenets:<br />- Minimum 8 characterst<br />- At least one uppercase letter<br />- At least one lowercase letter<br />- At least one digit")}
                                                    data-label={t('registration.popup.password', "Password")}
                                                    data-error={errors.password || ''}
                                                >
                                                    <input
                                                        name="password"
                                                        autoComplete="new-password"
                                                        placeholder={t('registration.popup.password', "Password")}
                                                        required
                                                        type="password"
                                                        className="input-area__input"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={fields.password || ''}
                                                        data-lowercase={false}
                                                        data-trim={false}
                                                        tabIndex={2}
                                                    />
                                                </label>
                                                <Tooltip id="password-requirements"/>
                                                <label
                                                    className={`input-area ${errors.confirm_password ? 'input-area--error' : ''}`}
                                                    data-label={t('registration.popup.confirm_password', "Confirm Password")}
                                                    data-error={errors.confirm_password || ''}>
                                                    <input
                                                        name="confirm_password"
                                                        autoComplete="new-password"
                                                        placeholder={t('registration.popup.confirm_password', "Confirm Password")}
                                                        required
                                                        type="password"
                                                        className="input-area__input"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={fields.confirm_password || ''}
                                                        data-lowercase={false}
                                                        data-trim={false}
                                                        tabIndex={3}
                                                    />
                                                </label>
                                                {/*<label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="remember"
                                                        checked={fields.remember}
                                                        onChange={handleChange}
                                                        tabIndex="3"
                                                    />
                                                    <div className="checkbox__label-content">
                                                        <div className="checkbox__square"></div>
                                                        <b>Remember me</b>
                                                    </div>
                                                </label>*/}
                                                <br/>
                                                <div className="form__info-box">
                                                    <T keyName="registration.popup.confirmation">By continuing you confirm that you are 18 years of age or older.</T>
                                                </div>
                                                <div className="btn-row">
                                                    <label className="btn btn--medium">
                                                        <input
                                                            type="submit"
                                                            tabIndex="4"
                                                        />
                                                        <span><T keyName="registration.popup.sign_up">Sign Up</T></span>
                                                    </label>
                                                </div>
                                                {/*<SocialLogin />*/}
                                                {/*<div className="form__info-box">
                                                    Если у вас возникают трудности с регистрацией,
                                                    обратитесь в нашу <Link to="#" onClick={() => dispatch(setShowTawk(true))}>live chat</Link>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div data-tab="tab1" style={{display: "none"}}>
                                    <div className="heading heading--center">
                                        <div className="heading__content">
                                            <h2>информация</h2>
                                        </div>
                                    </div>
                                    <div className="registration-popup__row">
                                        <div className="registration-popup__item">
                                            <div className="form-container">
                                                <label className="input-area" data-label="Имя" data-error="Имя">
                                                    <input type="text" className="input-area__input" placeholder="Имя"
                                                           value="" />
                                                </label>
                                                <label className="input-area" data-label="фамилия" data-error="фамилия">
                                                    <input type="text" className="input-area__input"
                                                           placeholder="фамилия" value="" />
                                                </label>
                                                <div className="form__row">
                                                    <div className="input-area" data-label="Страна" data-error="Страна">
                                                        <div className="input-drop-down">
                                                            <div className="input-drop-down__current">
                                                                Страна
                                                            </div>
                                                            <ul className="input-drop-down__list">
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="1"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_ru`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Россия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="2"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_en`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Англия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="3"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_de`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Германия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="4"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_pl`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Польша</span>
																		</span>
                                                                    </label>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="input-area" data-label="Город" data-error="Город">
                                                        <div className="input-drop-down">
                                                            <div className="input-drop-down__current">
                                                                Город
                                                            </div>
                                                            <ul className="input-drop-down__list">
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="1"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_ru`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Россия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="2"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_en`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Англия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="3"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_de`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Германия</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="4"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<img className="input-drop-down__img"
                                                                                 src={`${icons}#flag_pl`}
                                                                                 alt="" />
																			<span
                                                                                className="input-drop-down__info">Польша</span>
																		</span>
                                                                    </label>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="input-area" data-label="Адрес" data-error="Адрес">
                                                    <input type="text" className="input-area__input"
                                                           placeholder="Адрес" />
                                                </label>

                                            </div>
                                        </div>
                                        <div className="registration-popup__item">
                                            <div className="form-container">
                                                <label className="input-area" data-label="Телефон:"
                                                       data-error="Телефон:">
                                                    <input type="text" className="input-area__input"
                                                           placeholder="Телефон" />
                                                        <div className="input-drop-down input-drop-down--small">
                                                            <div className="input-drop-down__current">
                                                                <img className="input-drop-down__img"
                                                                     src={`${icons}#flag_ru`} alt="" />
                                                                    <span className="input-drop-down__info">+373</span>
                                                            </div>
                                                            <ul className="input-drop-down__list">
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="1"
                                                                               className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src={`${icons}#flag_en`} alt="" />
																		<span
                                                                            className="input-drop-down__info">+488</span>
																	</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="2"
                                                                               className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src={`${icons}#flag_de`} alt="" />
																		<span
                                                                            className="input-drop-down__info">+656</span>
																	</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="3"
                                                                               className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src={`${icons}#flag_pl`} alt="" />
																		<span
                                                                            className="input-drop-down__info">+002</span>
																	</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="4"
                                                                               className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src={`${icons}#flag_ru`} alt="" />
																		<span
                                                                            className="input-drop-down__info">+031</span>
																	</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="5"
                                                                               className="input-drop-down__radio" />
																	<span className="input-drop-down__content">
																		<img className="input-drop-down__img"
                                                                             src={`${icons}#flag_ru`} alt="" />
																		<span
                                                                            className="input-drop-down__info">+048</span>
																	</span>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                </label>
                                                <div className="form__row">
                                                    <label className="input-area" data-label="Индекс:"
                                                           data-error="Индекс:">
                                                        <input type="text" className="input-area__input"
                                                               placeholder="Индекс" />
                                                    </label>
                                                    <div className="input-area" data-label="Валюта" data-error="Валюта">
                                                        <div className="input-drop-down">
                                                            <div className="input-drop-down__current">
                                                                Валюта
                                                            </div>
                                                            <ul className="input-drop-down__list">
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="1"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Рубли</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="2"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Доллары</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="3"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Евро</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="country" value="4"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Криптовалюта</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form__row">
                                                    <label className="input-area" data-label="Год рождения:"
                                                           data-error="Год рождения:">
                                                        <input type="text" className="input-area__input"
                                                               placeholder="Год рождения" />
                                                    </label>
                                                    <div className="input-area" data-label="Пол" data-error="Пол">
                                                        <div className="input-drop-down">
                                                            <div className="input-drop-down__current">
                                                                <span className="input-drop-down__info">Мужской</span>
                                                            </div>
                                                            <ul className="input-drop-down__list">
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="sex" value="1"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Мужской</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                                <li className="input-drop-down__item">
                                                                    <label className="input-drop-down__label">
                                                                        <input type="radio" name="sex" value="2"
                                                                               className="input-drop-down__radio" />
																		<span className="input-drop-down__content">
																			<span
                                                                                className="input-drop-down__info">Женский</span>
																		</span>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="checkbox">
                                                    <input type="checkbox" checked="" />
                                                        <div className="checkbox__label-content">
                                                            <div className="checkbox__square"></div>
                                                            Я принимаю Условия пользования и
                                                            Политику конфиденциальности
                                                        </div>
                                                </label>
                                                <label className="checkbox">
                                                    <input type="checkbox" checked="" />
                                                        <div className="checkbox__label-content">
                                                            <div className="checkbox__square"></div>
                                                            Я хочу получать информацию об акциях
                                                            на свою электронную почту
                                                        </div>
                                                </label>
                                                <div className="btn-row">
                                                    <label className="btn btn--medium">
                                                        <input type="submit" />
                                                            <span>cоздать аккаунт</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div data-tab="tab2" style={{display: "none"}}>
                                    <div className="registration-popup__deposit-box">
                                        <div className="registration-popup__info-box">
                                            <div className="heading heading--center">
                                                <div className="heading__content">
                                                    <h2>Поздравляем!</h2>
                                                </div>
                                            </div>
                                            <div className="form__info-box">
                                                Вы успешно активировали свой аккаунт.
                                                Сделайте первый депозит от 1000 до 50000 рублей
                                                и получите 100% бонус на свой счёт.
                                            </div>
                                        </div>
                                        <div className="tab">
                                            <div className="tab__menu tab__menu--payment">
                                                <div className="tab__menu-item tab__menu-item--active" data-tab="tab0">
                                                    <div className="tab__menu-btn"><span>Bank card</span></div>
                                                </div>
                                                <div className="tab__menu-item" data-tab="tab1">
                                                    <div className="tab__menu-btn"><span>Cripto</span></div>
                                                </div>
                                                <div className="tab__menu-item" data-tab="tab2">
                                                    <div className="tab__menu-btn"><span>Переводы</span></div>
                                                </div>
                                            </div>
                                            <div className="tab__content">
                                                <div data-tab="tab0">
                                                    <div className="registration-popup__form-grid">
                                                        <div className="registration-popup__form-grid-order-1">
                                                            <div className="payment-card payment-card--form">
                                                                <div className="payment-card__body">
                                                                    <div className="payment-card__title">
                                                                        <div className="payment-card__title-img-box">
                                                                            <img
                                                                                src="assets/mg/payment-system/iconPay_Dogecoin.png"
                                                                                alt=""
                                                                                className="payment-card__title-img" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-2">
                                                            <div className="form-container">
                                                                <label className="input-area input-area--true-row"
                                                                       data-label="Введите сумму депозита:"
                                                                       data-error="Введите сумму депозита:">
                                                                    <input type="text" className="input-area__input"
                                                                           placeholder="Введите сумму депозита"
                                                                           value="50" />
                                                                        <div
                                                                            className="input-drop-down input-drop-down--line input-drop-down--auto">
                                                                            <div className="input-drop-down__current">
                                                                                <span
                                                                                    className="input-drop-down__info">EUR</span>
                                                                            </div>
                                                                            <ul className="input-drop-down__list">
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="1"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">RUB</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="2"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">USD</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="3"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">EUR</span>
																					</span>
                                                                                    </label>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-3">
                                                            <div className="form-container">
                                                                <div className="input-area"
                                                                     data-label="Платежная система"
                                                                     data-error="Платежная система">
                                                                    <div className="input-drop-down">
                                                                        <div className="input-drop-down__current">
																			<span className="input-drop-down__info">Платежная
																				система</span>
                                                                        </div>
                                                                        <ul className="input-drop-down__list">
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="1"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="2"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-5">
                                                            <div className="form-container">
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Приветственный 100% Бонус:</b>
                                                                                <b>+50 EUR</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Промо-код:</b>
                                                                                <b>+20 FS</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <div
                                                                    className="hide-input-area hide-input-area--button">
                                                                    <div className="hide-input-area__trigger">У меня
                                                                        есть
                                                                        промо-код
                                                                    </div>
                                                                    <label className="input-area"
                                                                           data-label="Введите промо-код"
                                                                           data-error="Введите промо-код">
                                                                        <input type="text" className="input-area__input"
                                                                               placeholder="Введите промо-код" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-4">
                                                            <div className="btn-row">
                                                                <label className="btn btn--medium">
                                                                    <input type="submit" />
                                                                        <span>депозит</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-6">
                                                            <div className="form-container">
                                                                <table className="table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Комиссия:</td>
                                                                        <td className="text-right"><b>5,5%</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Обработка:</td>
                                                                        <td className="text-right"><b>30 мин.</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Мин. лимит:</td>
                                                                        <td className="text-right"><b>1000 РУБ</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Макс. лимит:</td>
                                                                        <td className="text-right"><b>100 000
                                                                            РУБ</b></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-tab="tab1" style={{display: "none"}}>
                                                    <div className="registration-popup__form-grid">
                                                        <div className="registration-popup__form-grid-order-1">
                                                            <div className="payment-card payment-card--form">
                                                                <div className="payment-card__body">
                                                                    <div className="payment-card__title">
                                                                        <div className="payment-card__title-img-box">
                                                                            <img
                                                                                src="assets/mg/payment-system/iconPay_Dogecoin.png"
                                                                                alt=""
                                                                                className="payment-card__title-img" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-2">
                                                            <div className="form-container">
                                                                <label className="input-area input-area--true-row"
                                                                       data-label="Введите сумму депозита:"
                                                                       data-error="Введите сумму депозита:">
                                                                    <input type="text" className="input-area__input"
                                                                           placeholder="Введите сумму депозита"
                                                                           value="50" />
                                                                        <div
                                                                            className="input-drop-down input-drop-down--line input-drop-down--auto">
                                                                            <div className="input-drop-down__current">
                                                                                <span
                                                                                    className="input-drop-down__info">EUR</span>
                                                                            </div>
                                                                            <ul className="input-drop-down__list">
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="1"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">RUB</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="2"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">USD</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="3"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">EUR</span>
																					</span>
                                                                                    </label>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-3">
                                                            <div className="form-container">
                                                                <div className="input-area"
                                                                     data-label="Платежная система"
                                                                     data-error="Платежная система">
                                                                    <div className="input-drop-down">
                                                                        <div className="input-drop-down__current">
																			<span className="input-drop-down__info">Платежная
																				система</span>
                                                                        </div>
                                                                        <ul className="input-drop-down__list">
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="1"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="2"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-5">
                                                            <div className="form-container">
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Приветственный 100% Бонус:</b>
                                                                                <b>+50 EUR</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Промо-код:</b>
                                                                                <b>+20 FS</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <div
                                                                    className="hide-input-area hide-input-area--button">
                                                                    <div className="hide-input-area__trigger">У меня
                                                                        есть
                                                                        промо-код
                                                                    </div>
                                                                    <label className="input-area"
                                                                           data-label="Введите промо-код"
                                                                           data-error="Введите промо-код">
                                                                        <input type="text" className="input-area__input"
                                                                               placeholder="Введите промо-код" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-4">
                                                            <div className="btn-row">
                                                                <label className="btn btn--medium">
                                                                    <input type="submit" />
                                                                        <span>депозит</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-6">
                                                            <div className="form-container">
                                                                <table className="table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Комиссия:</td>
                                                                        <td className="text-right"><b>5,5%</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Обработка:</td>
                                                                        <td className="text-right"><b>30 мин.</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Мин. лимит:</td>
                                                                        <td className="text-right"><b>1000 РУБ</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Макс. лимит:</td>
                                                                        <td className="text-right"><b>100 000
                                                                            РУБ</b></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-tab="tab2" style={{display: "none"}}>
                                                    <div className="registration-popup__form-grid">
                                                        <div className="registration-popup__form-grid-order-1">
                                                            <div className="payment-card payment-card--form">
                                                                <div className="payment-card__body">
                                                                    <div className="payment-card__title">
                                                                        <div className="payment-card__title-img-box">
                                                                            <img
                                                                                src="assets/mg/payment-system/iconPay_Dogecoin.png"
                                                                                alt=""
                                                                                className="payment-card__title-img" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-2">
                                                            <div className="form-container">
                                                                <label className="input-area input-area--true-row"
                                                                       data-label="Введите сумму депозита:"
                                                                       data-error="Введите сумму депозита:">
                                                                    <input type="text" className="input-area__input"
                                                                           placeholder="Введите сумму депозита"
                                                                           value="50" />
                                                                        <div
                                                                            className="input-drop-down input-drop-down--line input-drop-down--auto">
                                                                            <div className="input-drop-down__current">
                                                                                <span
                                                                                    className="input-drop-down__info">EUR</span>
                                                                            </div>
                                                                            <ul className="input-drop-down__list">
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="1"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">RUB</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="2"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">USD</span>
																					</span>
                                                                                    </label>
                                                                                </li>
                                                                                <li className="input-drop-down__item">
                                                                                    <label
                                                                                        className="input-drop-down__label">
                                                                                        <input type="radio"
                                                                                               name="country" value="3"
                                                                                               className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">EUR</span>
																					</span>
                                                                                    </label>
                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-3">
                                                            <div className="form-container">
                                                                <div className="input-area"
                                                                     data-label="Платежная система"
                                                                     data-error="Платежная система">
                                                                    <div className="input-drop-down">
                                                                        <div className="input-drop-down__current">
																			<span className="input-drop-down__info">Платежная
																				система</span>
                                                                        </div>
                                                                        <ul className="input-drop-down__list">
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="1"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                            <li className="input-drop-down__item">
                                                                                <label
                                                                                    className="input-drop-down__label">
                                                                                    <input type="radio"
                                                                                           name="payment-type" value="2"
                                                                                           className="input-drop-down__radio" />
																					<span
                                                                                        className="input-drop-down__content">
																						<span
                                                                                            className="input-drop-down__info">Платежная
																							система</span>
																					</span>
                                                                                </label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-5">
                                                            <div className="form-container">
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Приветственный 100% Бонус:</b>
                                                                                <b>+50 EUR</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <label className="checkbox checkbox--alert">
                                                                    <input type="checkbox" checked="" />
                                                                        <div className="checkbox__label-content">
                                                                            <div className="checkbox__square"></div>
                                                                            <div className="row row--between">
                                                                                <b>Промо-код:</b>
                                                                                <b>+20 FS</b>
                                                                            </div>
                                                                        </div>
                                                                </label>
                                                                <div
                                                                    className="hide-input-area hide-input-area--button">
                                                                    <div className="hide-input-area__trigger">У меня
                                                                        есть
                                                                        промо-код
                                                                    </div>
                                                                    <label className="input-area"
                                                                           data-label="Введите промо-код"
                                                                           data-error="Введите промо-код">
                                                                        <input type="text" className="input-area__input"
                                                                               placeholder="Введите промо-код" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-4">
                                                            <div className="btn-row">
                                                                <label className="btn btn--medium">
                                                                    <input type="submit" />
                                                                        <span>депозит</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="registration-popup__form-grid-order-6">
                                                            <div className="form-container">
                                                                <table className="table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>Комиссия:</td>
                                                                        <td className="text-right"><b>5,5%</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Обработка:</td>
                                                                        <td className="text-right"><b>30 мин.</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Мин. лимит:</td>
                                                                        <td className="text-right"><b>1000 РУБ</b>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Макс. лимит:</td>
                                                                        <td className="text-right"><b>100 000
                                                                            РУБ</b></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}

const validatorFields = {
    email: (fields, errors, t, normalize = false) => {
        if (!fields.email || !validator.isEmail(fields.email)) {
            return errors.email = t('registration.popup.incorrect_email_error', 'Incorrect email');
        }
        fields.email = normalize ? validator.normalizeEmail(fields.email) : fields.email;
    },
    password: (fields, errors, t) => {
        if (!fields.password || validator.isEmpty(fields.password)) {
            return errors.password = t('registration.popup.insert_password_error', 'Please insert password');
        }
        if (!validator.isStrongPassword(fields.password, {minSymbols: 0})) {
            return errors.password = t('registration.popup.insert_strong_password_error', 'Please insert a strong password');
        }
    },
    confirmPassword: (fields, errors, t) => {
        if (!fields.confirm_password || validator.isEmpty(fields.confirm_password)) {
            return errors.confirm_password = t('registration.popup.confirm_password_error', 'Please confirm password');
        }
        if (fields.password !== fields.confirm_password) {
            return errors.confirm_password = t('registration.popup.passwords_match_error', 'Passwords don\'t match');
        }
    },
    currency_id: (fields, errors, t) => {
        if (!fields.currency_id) {
            return errors.currency_id = t('registration.popup.select_currency_error', 'Please select currency');
        }
    }
};
const validate = (fields, errors = {}, t) => {
    validatorFields.email(fields, errors, t, true);
    validatorFields.password(fields, errors, t);
    validatorFields.confirmPassword(fields, errors, t);
    validatorFields.currency_id(fields, errors, t);

    return errors;
};

export default RegistrationPopup;