import {useEffect, useState} from 'react';
import {RouterProvider} from 'react-router-dom';
import mainRoutes from './routes/main.routes';
import {useSelector} from 'react-redux';

function App() {
    const userData = useSelector((state) => state.user.userData);

    useEffect(() => {
        userData ? document.body.classList.add('on-line') : document.body.classList.remove('on-line');
    }, [userData]);

    return (
        <RouterProvider router={mainRoutes} />
    );
}

export default App;
