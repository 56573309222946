import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {T} from "@tolgee/react";

import {s3Config} from '../../config/aws.config';
import Hamburger from './Hamburger';
import MainMenuElements from './MainMenuElements';
import CategoriesMenuElements from './CategoriesMenuElements';
import helpersUtils from '../../utils/helpers.utils';
import LanguageMenu from './LanguageMenu';
import {setShowSearch, setShowMobileMenu, setShowOverlay, setPopup, setShowTawk} from '../../redux/states.slice';
import {LOGIN_POPUP, REGISTRATION_POPUP} from '../PopupContainer';
import MobileInfoMenu from './MobileInfoMenu';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

function MobileMenu() {
    const [showLangMenu, setShowLangMenu] = useState(false);
    const showMobileMenu = useSelector((state) => state.states.mobileMenu);
    const dispatch = useDispatch();

    useEffect(() => {
        helpersUtils.setOverlayClickAction(showMobileMenu ? closeMobileMenu : null);
        helpersUtils.setEscapeEventAction(showMobileMenu ? closeMobileMenu : null);
        dispatch(setShowOverlay(showMobileMenu));
    }, [showMobileMenu]);

    const closeMobileMenu = () => {
        dispatch(setShowMobileMenu(false));
    };

    return (
        <div className={`mobile-menu ${showMobileMenu ? 'mobile-menu--active' : ''}`}>
            <div className="mobile-menu__action-area">
                <Hamburger clickAction={closeMobileMenu} active={true}/>
                <div className="search-widget-trigger" onClick={() => {
                    closeMobileMenu();
                    dispatch(setShowSearch(true));
                }}>
                    <img src={`${icons}#ico_search`} alt="" className="search-widget-trigger__img"/>
                </div>
                <div className="mobile-menu__log-reg-area">
                    <div className="registration">
                        <div className="registration__row">
                            <Link to="#registration"
                                  onClick={() => {
                                      dispatch(setPopup({popup: REGISTRATION_POPUP}));
                                      closeMobileMenu();
                                  }}
                                  className="small-btn registration-link registration-trigger">
                                <span className="small-btn__info"><span><T keyName='main.menu.registration'>Registration</T></span></span>
                            </Link>
                            <Link to="#login"
                                  onClick={() => {
                                      dispatch(setPopup({popup: LOGIN_POPUP}));
                                      closeMobileMenu();
                                  }}
                                  className="small-btn small-btn--black login-popup-trigger">
                                <span className="small-btn__info"><T keyName='main.menu.login'>Login</T></span>
                            </Link>
                        </div>
                    </div>
                    <div className="login">
                        <div className="login__row">
                            <Link to={helpersUtils.getLanguageLink('/account/deposit')}
                                  className="small-btn small-btn--big deposit-link"><span><T keyName='main.menu.deposit'>Deposit</T></span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="small-btn small-btn--white" onClick={() => {
                    dispatch(setShowTawk(true));
                    closeMobileMenu();
                }}>
                    <img src={`${icons}#link_chat`} alt="" className="small-btn__img"/>
                </div>
            </div>
            <div className="mobile-menu__nav">
                <div className="mobile-menu__row">
                    <ol className="mobile-menu__list">
                        <MainMenuElements mobile={true}/>
                    </ol>
                    <ol className="mobile-menu__list mobile-menu__list--white">
                        <CategoriesMenuElements mobile={true}/>
                    </ol>
                </div>
                <MobileInfoMenu />
                <ol className={`mobile-lang ${showLangMenu ? 'mobile-lang--active' : ''}`}
                    onClick={() => setShowLangMenu(show => !show)}
                >
                    <LanguageMenu mobile={true}/>
                </ol>
            </div>
        </div>
    );
}

export default MobileMenu;