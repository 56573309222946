import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {T, useTranslate} from "@tolgee/react";

import Select from '../../components/ui/Select';
import depositsServices from '../../services/deposits.services';
import withdrawalsServices from '../../services/withdrawals.services';
import playersServices from '../../services/players.services';
import helpersUtils from '../../utils/helpers.utils';

const BATCH_SIZE = 20;

const AccountPaymentsHistory = () => {
    const { t } = useTranslate();

    const types = {
        deposit: {
            name: t('account.payment.history.deposit_statuses_title', 'Deposit'),
            statuses: {
                1: t('account.payment.history.pending_status', 'Pending'),
                2: t('account.payment.history.completed_status', 'Completed'),
                3: t('account.payment.history.failed_status', 'Failed'),
                4: t('account.payment.history.canceled_status', 'Canceled'),
            }
        },
        withdrawal: {
            name: t('account.payment.history.withdrawal_statuses_title', 'Withdrawal'),
            statuses: {
                1: t('account.payment.history.pending_status', 'Pending'),
                2: t('account.payment.history.completed_status', 'Completed'),
                3: t('account.payment.history.failed_status', 'Failed'),
                4: t('account.payment.history.canceled_status', 'Canceled'),
                5: t('account.payment.history.processing_status', 'Processing'),
            }
        },
    };

    const [records, setRecords] = useState([]);
    const [selectedType, setSelectedType] = useState(Object.keys(types)[0]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [start,] = useState(0);
    const [end, setEnd] = useState(BATCH_SIZE);
    const [playerID, setPlayerID] = useState('');
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        playersServices.index().then((data) => {
            if (data) {
                const players = {};
                data.forEach(player => {
                    players[player.id] = player;
                });
                setPlayers(players);
            }
        });
    }, []);

    useEffect(() => {
        const filters = {
            p: {
                start,
                end
            },
            f: {
                status: selectedStatus || undefined,
                player_id: playerID || undefined
            }
        };

        const requestMethod = selectedType === 'deposit' ? depositsServices.index(filters) : withdrawalsServices.index(filters);

        requestMethod.then((data) => {
            data && setRecords(data || []);
        });
    }, [selectedType, selectedStatus, start, end, playerID]);

    if (records.length === 0 || players.length === 0) return null;

    console.log(records)
    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName="account.payment.history.payments_history_title">Payments history</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="account__filter-box">
                                        <form className="form" action="">
                                            <div className="account__filter-row">
                                                <div className="account__filter-item">
                                                    <label className={`select select--big`}>
                                                        <Select
                                                            name="type"
                                                            options={Object.keys(types).map(type => {
                                                                return {
                                                                    name: types[type].name,
                                                                    value: type
                                                                };
                                                            })}
                                                            selectedValue={selectedType}
                                                            setValue={(value) => {
                                                                setSelectedStatus('');
                                                                setSelectedType(value);
                                                            }}
                                                        />
                                                    </label>
                                                    <label className={`select select--big`}>
                                                        <Select
                                                            includeEmpty={t('account.payment.history.status_select', 'Status')}
                                                            name="status"
                                                            options={Object.keys(types[selectedType].statuses).map(status => {
                                                                return {
                                                                    name: types[selectedType].statuses[status],
                                                                    value: status
                                                                };
                                                            })}
                                                            selectedValue={selectedStatus}
                                                            setValue={(value) => {
                                                                setSelectedStatus(value);
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                                {/* <div className="account__filter-item">
                                                    <label className="input-area" data-label={_t("Дата ставки от")} data-error={_t("Дата ставки от")}>
                                                        <input type="text" className="input-area__input" placeholder={_t("Дата ставки от")} />
                                                    </label>
                                                    <label className="input-area" data-label={_t("Дата ставки до")} data-error={_t("Дата ставки до")}>
                                                        <input type="text" className="input-area__input" placeholder={_t("Дата ставки до")} />
                                                    </label>
                                                </div>*/}
                                                <div className="account__filter-item">
                                                    <div className="select-row">
                                                        <label className={`select`}>
                                                            <Select
                                                                name="currencies"
                                                                includeEmpty={t('account.payment.history.currency_select', 'Currency')}
                                                                options={Object.keys(players).map(playerID => {
                                                                    return {
                                                                        name: players[playerID].currency_code,
                                                                        value: playerID
                                                                    };
                                                                })}
                                                                setValue={(value) => {
                                                                    setPlayerID(value);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th><span className="table__sort"><T keyName="account.payment.history.payment_method_header">Payment method</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.payment.history.amount_header">Amount</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.payment.history.date_header">Date</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.payment.history.status_header">Payment method</T></span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                records?.data?.map((record, index) => {
                                                    const status = types[selectedType].statuses[record.status];

                                                    return (
                                                        <tr
                                                            key={`id-${index}`}
                                                            className={record.status === 1 ? 'table__cancel' : ''}
                                                        >
                                                            <td>{record.paymethod}</td>
                                                            <td>{helpersUtils.unitAmount(record.amount, players[record.player_id].currency_subunit)}</td>
                                                            <td>{format(new Date(record.created_at), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                            <td>{status}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                         records.total > end && (
                                             <div className="btn-row">
                                                <div
                                                    className="btn btn--black"
                                                    onClick={() => {
                                                        setEnd(end => end + BATCH_SIZE);
                                                    }}
                                                >
                                                    <span>{t('pagination.show_more', 'Show more')}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountPaymentsHistory;