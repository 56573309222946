import { createSlice } from '@reduxjs/toolkit';

export const statesSlice = createSlice({
    name: 'states',
    initialState: {
        overlay: false,
        mobileMenu: false,
        search: false,
        popup: null,
        tawk: false,
        userMenu: false
    },
    reducers: {
        setShowSearch: (state, action) => {
            state.search = action.payload;
        },
        setPopup: (state, action) => {
            state.popup = action.payload;
        },
        setShowOverlay: (state, action) => {
            state.overlay = action.payload;
        },
        setShowMobileMenu: (state, action) => {
            state.mobileMenu = action.payload;
        },
        setShowTawk: (state, action) => {
            state.tawk = action.payload;
        },
        setShowUserMenu: (state, action) => {
            state.userMenu = action.payload;
        },
    }
});

export const {
    setShowSearch,
    setShowMobileMenu,
    setPopup,
    setShowOverlay,
    setShowTawk,
    setShowUserMenu
} = statesSlice.actions;

export default statesSlice.reducer;