const languages = {
    'en-US': {
        language: 'en',
        name: 'English',
        var1: 'flag_en'
    },
    'ru-RU': {
        language: 'ru',
        name: 'Русский',
        var1: 'flag_ru'
    },
    'de-DE': {
        language: 'de',
        name: 'Deutsch',
        var1: 'flag_de'
    },
    'pt-BR': {
        language: 'pt',
        name: 'Portuguese',
        var1: 'flag_br'
    },
    'es-ES': {
        language: 'es',
        name: 'Spanish',
        var1: 'flag_sp'
    },
    'ro-RO': {
        language: 'ro',
        name: 'Romanian',
        var1: 'flag_ro'
    },
    'fr-FR': {
        language: 'fr',
        name: 'France',
        var1: 'flag_fr'
    },
    'uk-UA': {
        language: 'ua',
        name: 'Украинский',
        var1: 'flag_ua'
    }
};
const languageList = ['en-US', 'ru-RU', 'de-DE', 'pt-BR', 'es-ES', 'ro-RO', 'fr-FR', 'uk-UA']

export {languages, languageList};