import {useState} from 'react';
import {T} from "@tolgee/react";

import InfoMenuElements from './InfoMenuElements';

const MobileInfoMenu = () => {
    const [showInfoMenu, setShowInfoMenu] = useState(false);

    return (
        <ol className="mobile-menu__list mobile-menu__list--transparent">
            <li className={`mobile-menu__item mobile-menu__item--sub-menu ${showInfoMenu ? 'mobile-menu__item--active' : ''}`}>
                <div className="nav-link" onClick={() => {
                    setShowInfoMenu(show => !show);

                }}>
                    <span className="nav-link__info"><T keyName='mobile.info.menu.information'>Information</T></span>
                </div>
                <ol className={`sub-menu ${showInfoMenu ? 'sub-menu--active' : ''}`}>
                    <InfoMenuElements mobile={true} />
                </ol>
            </li>
        </ol>
    )
};

export default MobileInfoMenu;