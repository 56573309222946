import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import validator from 'validator/es';
import {useNavigate, useParams} from 'react-router-dom';
import {T, useTranslate} from "@tolgee/react";

import helpersUtils from '../utils/helpers.utils';
import projectsServices from '../services/projects.services';
import Select from './ui/Select';
import {LOGIN_POPUP} from './PopupContainer';

function FastDeposit() {
    const { t } = useTranslate()
    const userData = useSelector((state) => state.user.userData);

    const [amount, setAmount] = useState(50);
    const [availableCurrencies, setAvailableCurrencies] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [amountError, setAmountError] = useState('');
    const navigate = useNavigate();
    const {lang: currentLanguage, slug} = useParams();

    useEffect(() => {
        projectsServices.getAvailableCurrencies().then(data => {
            data && setAvailableCurrencies(data);
            const defaultCurrency = userData?.player?.currency_id || data[0].id;
            data && setSelectedCurrency(data.find(currency => currency.id === defaultCurrency));
        })
    }, []);

    useEffect(() => {
        userData && setSelectedCurrency(availableCurrencies?.find(currency => currency.id === userData?.player?.currency_id));
    }, [userData?.player.currency_id])

    const validateAmount = (value) => {
        if (value && !validator.isFloat(value.toString())) {
            setAmountError(t('fast.deposit.invalid_amount' ,'Invalid amount'));
            return false;
        }
        return true;
    }

    if (!availableCurrencies || !selectedCurrency) return null;

    return (
        <section className="section section--fast-dep">
            <div className="wrapper">
                <div className="fast-dep">
                    <div className="fast-dep__title h1">
                        <T keyName="fast.deposit.get_bonus">Get a 100% bonus</T>
                        <br/>
                        + 100 <T keyName="fast.deposit.free_spins">free spins</T>
                    </div>
                    <form action="src" className="fast-dep__form">
                        <fieldset>
                            <label className="input-area input-area--true-row input-area--select" data-label={`${t('fast.deposit.enter_deposit_amount', 'Enter deposit amount')}:`}>
                                <input type="text"
                                       className="input-area__input"
                                       placeholder={t('fast.deposit.enter_deposit_amount', 'Enter deposit amount')}
                                       onChange={(event) => {
                                           let [, value] = helpersUtils.getFormEventData(event);
                                           value = value.replace(/[^0-9.]/g, '')
                                           let t = 0;
                                           value = value.replace(/./g, match => match === '.' && ++t > 1 ? '' : match )
                                           setAmount(value);
                                       }}
                                       onBlur={(event) => {
                                           const [, value] = helpersUtils.getFormEventData(event);
                                           validateAmount(value) && setAmountError('');
                                       }}
                                       value={amount}
                                />
                                <Select
                                    customSelectClass="input-area__select"
                                    name="type"
                                    options={availableCurrencies.map(currency => {
                                        return {
                                            name: currency.code,
                                            value: currency.id
                                        };
                                    })}
                                    selectedValue={selectedCurrency.id}
                                    setValue={(value) => {
                                        setSelectedCurrency(availableCurrencies.find(currency => currency.id === parseInt(value)));
                                    }}
                                />
                            </label>
                            <label className="checkbox checkbox--alert">
                                <input type="checkbox"  />
                                <div className="checkbox__label-content">
                                    <div className="checkbox__square"></div>
                                    <div className="row row--between">
                                        <b>{`${t('fast.deposit.welcome_bonus', 'Welcome bonus')}:`}</b>
                                        <b>+{amount} {selectedCurrency.code.toUpperCase()}</b>
                                    </div>
                                </div>
                            </label>
                            <div className="btn-row">
                                <label className="btn"
                                       onClick={() => {
                                           if (userData && userData.player) {
                                               navigate(helpersUtils.getLanguageLink('/account/deposit', currentLanguage));
                                            } else {
                                               navigate(`#${LOGIN_POPUP}`)
                                           }
                                       }}
                                >
                                    <span>{`${t('fast.deposit.play_with', 'Play with')}  ${amount * 2} ${selectedCurrency.code.toUpperCase()}`}</span>
                                </label>
                            </div>
                            {/*<div className="hide-input-area">
                                <div className="hide-input-area__trigger">У меня есть промо-код</div>
                                <label className="input-area" data-label="Введите промо-код"
                                       data-error="Введите промо-код">
                                    <input type="text" className="input-area__input"
                                           placeholder="Введите промо-код"/>
                                </label>
                            </div>*/}
                        </fieldset>
                    </form>
                </div>
            </div>
        </section>
    );
}

const validatorFields = {
    email: (fields, errors, t, normalize = false) => {
        if (!fields.email || !validator.isEmail(fields.email)) {
            return errors.email = t('fast.deposit.incorrect_email', 'Incorrect email');
        }
        fields.email = normalize ? validator.normalizeEmail(fields.email) : fields.email;
    },
    password: (fields, errors, t) => {
        if (!fields.password || validator.isEmpty(fields.password)) {
            return errors.password = t('fast.deposit.insert_password', 'Please insert password');
        }
    }
};
const validate = (fields, errors = {}, t) => {
    validatorFields.email(fields, errors, t, true);
    validatorFields.password(fields, errors, t );

    return errors;
};

export default FastDeposit;