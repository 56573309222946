import React from 'react';

const BonusCard = ({ vertical, imgUrl, title, info, children }) => {
    const classNames = `bonus-card ${vertical ? 'bonus-card--vertical' : ''}`

    return (
        <div className={classNames}>
            <div className="bonus-card__img-box">
                <img src={imgUrl} alt={title} className="bonus-card__img" />
            </div>
            <div className="bonus-card__content">
                <div className="bonus-card__info-box">
                    <div className="bonus-card__info bonus-card__info--big">
                        {title}
                    </div>
                    {info &&
                        <div className="bonus-card__info">
                            {info}
                        </div>
                    }
                </div>
                <div className="bonus-card__btn-row">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BonusCard;