import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';

import infoServices from '../services/info.services';

const Info = () => {
    const {page, lang: currentLanguage} = useParams();
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        infoServices.get(page).then((data) => {
            if (data) setPageData(data);
        })
    }, [page, currentLanguage]);

    if (!pageData) return null;

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2>{pageData.title}</h2>
                        </div>
                    </div>
                    <div className="rules">
                        <div className="rules__body">
                            <div className="rules__inner" dangerouslySetInnerHTML={{__html: pageData.content}} />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Info;