import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Fragment} from 'react';
import {useTranslate} from "@tolgee/react";

import helpersUtils from '../../utils/helpers.utils';
import {setPopup, setShowUserMenu} from '../../redux/states.slice';
import {LOGOUT_POPUP} from '../PopupContainer';

const getMenuElements = () => [
    {
        url: 'cancel-withdrawal',
        title: 'Cancel withdrawal',
        translationKey: 'user.menu.elements.cancel_withdrawal'
    },
    {
        url: 'payment-history',
        title: 'Payment history',
        translationKey: 'user.menu.elements.payment_history'
    },
    {
        url: 'game-history',
        title: 'Game history',
        translationKey: 'user.menu.elements.game_history'
    },
    {
        url: 'favorite',
        title: 'Favorite games',
        translationKey: 'user.menu.elements.favorite_games'
    },
    {
        url: 'casino-bonus',
        title: 'Bonuses and free spins',
        translationKey: 'user.menu.elements.bonuses_and_free_spins'
    },
    {
        url: 'sport-bonus',
        title: 'Sport bonuses',
        translationKey: 'user.menu.elements.sport_bonuses'
    },
    {
        url: 'promocodes',
        title: 'Promocodes',
        translationKey: 'user.menu.elements.promocodes'
    },
    {
        url: 'verification',
        title: 'Verification',
        translationKey: 'user.menu.elements.verification'
    },
    {
        url: 'profile',
        title: 'Profile',
        translationKey: 'user.menu.elements.profile'
    },
    {
        url: 'notifications',
        title: 'Notifications',
        translationKey: 'user.menu.elements.notifications'
    },
    {
        url: 'settings',
        title: 'Settings',
        translationKey: 'user.menu.elements.settings'
    }
];
const UserMenuElements = () => {
    const { t } = useTranslate()
    const location = useLocation();
    const dispatch = useDispatch();

    let currentPath = location.pathname;
    currentPath = currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

    return (
        <Fragment>
            {
                getMenuElements().map((element) => {
                    let link = element.url.slice(0, 4) === 'http' ?
                        element.url
                        :
                        `${helpersUtils.getLanguageLink(`/account/${element.url}`)}`;

                    link = link.slice(-1) === '/' ? link.slice(0, -1) : link;

                    return (
                        <li key={element.url} className="user-menu__item">
                            <Link to={link}
                                  onClick={() => dispatch(setShowUserMenu(false))}
                                  className={`user-menu__link ${link === currentPath ? 'user-menu__link--current' : ''}`}>
                                <span className={`user-menu__link-info`}>{t(element.translationKey, element.title)}</span>
                            </Link>
                        </li>
                    );
                })
            }
            <li className="user-menu__item">
                <Link to={'/'}
                      className={`user-menu__link`}
                      onClick={(ev) => {
                          ev.preventDefault();
                          dispatch(setPopup({popup: LOGOUT_POPUP}));
                          dispatch(setShowUserMenu(false));
                      }}
                >
                    <span className={`user-menu__link-info`}>{t('user.menu.elements.logout', 'Logout')}</span>
                </Link>
            </li>
        </Fragment>
        //user-menu__link--current
    );
};

export default UserMenuElements;