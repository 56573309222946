import requestUtils from '../utils/request.utils';

const infoServices = {};

infoServices.get = async (page) => {
    try {
        return requestUtils.get(`/info/${page}`);
    } catch (err) {
        console.error(err);
    }
};
export default infoServices;