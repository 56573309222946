import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {T} from "@tolgee/react";

import GameProvidersList from '../components/GameProvidersList';
import helpersUtils from '../utils/helpers.utils';
import gamesServices from '../services/games.services';
import Game from '../components/Game';
import {LoadMore} from '../components/Other';

function GameProviders(props) {
    const {batchSize = 48} = props;
    const [providerGames, setProviderGames] = useState({});
    const [endSize, setEndSize] = useState(batchSize);
    const {provider} = useParams();

    useEffect(() => {
        setEndSize(batchSize);
    }, [provider])

    useEffect(() => {
        if (provider) {
            const {name, id} = helpersUtils.splitNameAndID(provider);
            gamesServices.getByProvider(id, 0, endSize).then(setProviderGames);
        } else {
            gamesServices.getAllGames(0, endSize).then(setProviderGames);
        }
    }, [provider, endSize]);

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading heading--between">
                        <div className="heading__content">
                            <h2><T keyName="game.providers.providers_title">Providers</T></h2>
                        </div>
                    </div>
                    <div className="provider-catalog">
                        <div className="wrapper">
                            <div className="provider-catalog__grid">
                                <GameProvidersList />
                            </div>
                        </div>
                    </div>
                    <div className='game-grid'>
                        {
                            providerGames?.data?.map((game) => {
                                return <Game key={game.id} game={game}/>;
                            })
                        }
                    </div>
                    {!props.batchSize && providerGames.hasMore && <LoadMore setEnd={setEndSize} batchSize={batchSize}/>}
                </div>
            </section>
        </main>
    );
}

export default GameProviders;