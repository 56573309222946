import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import {T} from "@tolgee/react";

import {s3Config} from '../../config/aws.config';
import helpersUtils from '../../utils/helpers.utils';
import {setPopup, setShowOverlay, setShowTawk, setShowUserMenu} from '../../redux/states.slice';
import Hamburger from './Hamburger';
import UserMenuElements from './UserMenuElements';
import Avatar from '../ui/Avatar';
import {CURRENCY_POPUP} from '../PopupContainer';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

function UserMenu() {
    const userData = useSelector((state) => state.user.userData);
    const showUserMenu = useSelector((state) => state.states.userMenu);
    const dispatch = useDispatch();

    useEffect(() => {
        helpersUtils.setOverlayClickAction(showUserMenu ? closeUserMenu : null);
        helpersUtils.setEscapeEventAction(showUserMenu ? closeUserMenu : null);
        dispatch(setShowOverlay(showUserMenu));
    }, [showUserMenu]);

    const closeUserMenu = () => {
        dispatch(setShowUserMenu(false));
    };


    if (!userData) return null;

    const balance = helpersUtils.unitAmount(userData?.player?.balance || 0, userData?.player?.currency_subunit, userData?.player?.currency_decimal_digits);
    const balanceParts = balance ? helpersUtils.getAmountParts(balance) : [0,0];

    return (
        <div className={`user-menu ${showUserMenu ? 'user-menu--active' : ''}`}>
            <div className="user-menu__action-area">
                <Link to={helpersUtils.getLanguageLink('/account/deposit')}
                      onClick={closeUserMenu}
                      className="small-btn user-menu__deposit-link"><span
                    className="small-btn__info"><T keyName='user.menu.deposit'>Deposit</T></span></Link>
                <Link to={helpersUtils.getLanguageLink('/account/withdraw')}
                      onClick={closeUserMenu}
                      className="small-btn small-btn--black"><span
                    className="small-btn__info"><T keyName='user.menu.withdraw'>Withdraw</T></span></Link>
                <div className="small-btn small-btn--white" onClick={() => {
                    dispatch(setShowTawk(true));
                    closeUserMenu();
                }}>
                    <img src={`${icons}#link_chat`} alt="" className="small-btn__img"/>
                </div>
                <Hamburger clickAction={closeUserMenu} active={true}/>

            </div>
            <div className="user-menu__nav">
                <ol className="user-menu__list">
                    <UserMenuElements />
                </ol>
            </div>
            <div className="user-menu__footer">
                <div className="user">
                    <div className="user__header">
                        <div className="user__value-side">
                            <div className="user__value-item">{balanceParts[0]} <sup>{balanceParts[1]}</sup></div>
                            <div className="user__value-item user__value-item--bonus">
                                {/*<svg className="user__value-item-icon" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Слой_1" x="0px"
                                     y="0px" width="20px" height="20px" viewBox="0 0 20 20"
                                     style="enable-background:new 0 0 20 20;" xml:space="preserve">
							<path id="giftbox" style="fill: #ff294c;"
                                  d="M19,7h-5c0.8,0,1.3-0.3,1.9-0.9c0.6-0.6,0.9-1.3,0.9-2.1s-0.3-1.5-0.9-2.1C15.3,1.3,14.8,1,14,1  c-0.9,0-1.8,0.3-2.3,1L10,4L8.3,2C7.8,1.3,6.9,1,6,1C5.2,1,4.7,1.3,4.1,1.9C3.5,2.5,3.2,3.2,3.2,4s0.3,1.5,0.9,2.1  C4.7,6.7,5.2,7,6,7H1L0,8v3l1,1h1v6l1,1h14l1-1v-6h1l1-1V8L19,7z M13,3.1c0.2-0.3,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4  C15,3.3,15.1,3.6,15.1,4S15,4.7,14.7,4.9c-0.2,0.3-0.5,0.4-0.9,0.4h-2.5L13,3.1z M6.1,5.3c-0.3,0-0.6-0.1-0.9-0.4  C5,4.7,4.9,4.4,4.9,4c0-0.4,0.1-0.7,0.4-0.9c0.2-0.3,0.5-0.4,0.9-0.4c0.4,0,0.7,0.1,0.9,0.4l1.6,2.2L6.1,5.3L6.1,5.3z M12,16l-1,1H9  l-1-1V7h4V16z"></path>
						</svg>*/}
                                <span>{balanceParts[0]}<sup>{balanceParts[1]}</sup></span>
                            </div>
                        </div>
                        <div className="currency-current-trigger"
                             onClick={() => {
                                 dispatch(setPopup({popup: CURRENCY_POPUP}));
                                 closeUserMenu();
                             }}
                        >
                            <div className="currency-item currency-item--current">
                                <div className="currency-item__icon">
                                    {userData?.player?.currency_symbol}
                                </div>
                                <div className="currency-item__name">{userData.player.currency_code}</div>
                            </div>
                        </div>
                        <div className="user__short-info">
                            <div className="user__status-box">
                                <div className="user__name">{helpersUtils.getNickname(userData)}</div>
                                {/*<div className="user__status">новичок</div>*/}
                            </div>
                        </div>
                        <div className="user__img-area">
                            <div className="user__img-box">
                                <Avatar />
                            </div>
                        </div>
                    </div>
                    <div className="user__progress">
                        <div className="user__progress-box">
                            <div className="user__progress-line" style={{width: "65%"}}></div>
                        </div>
                        <div className="user__progress-percent">65%</div>
                    </div>
                    {/*<div className="user__alert">3</div>*/}
                </div>
            </div>
        </div>
    );
}

export default UserMenu;