import {Link, useParams} from 'react-router-dom';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {T, useTranslate} from "@tolgee/react";

import gamesServices from '../services/games.services';
import usersServices from '../services/users.services';
import helpersUtils from '../utils/helpers.utils';
import {LOGIN_POPUP} from './PopupContainer';
import {getProfileLink} from '../utils/links.utils';

const Game = (props) => {
    const { t } = useTranslate()
    const {lang: currentLanguage} = useParams();
    const userData = useSelector((state) => state.user.userData);
    const {game, hideFavourite} = props;
    const [, setRefresh] = useState(0);
    const gameSlug = gamesServices.getSlug(game.name, game.id);
    const isProfileCompleted = userData && usersServices.isProfileCompleted(userData);
    const isLoggedIn = userData && userData.uuid;


    let playLink = gamesServices.getPlayLink(gameSlug, currentLanguage);
    let tooltipText = '';
    let clickAction = () => {};

    if (!isLoggedIn) {
        playLink = `#${LOGIN_POPUP}`;
        tooltipText = t('game.first_login_notification', 'Please login first to play in real mode');
    } else if (!isProfileCompleted) {
        playLink = getProfileLink();
        const message = t('game.complete_profile_notification', 'Please complete your profile to play in real mode');
        tooltipText = message;
        clickAction = () => {
            toast(message);
        }
    }

    return (
        <div key={`game-${game.id}`} className="game-item">
            <Link to={helpersUtils.getLanguageLink('/games/play/mobile/' + gameSlug)}
                  className="game-item__widget-trigger"></Link>
            <div className="game-item__box">
                <div className="game-item__img-box" data-title={game.name}>
                    {
                        game.gametags.map((tag) => {
                            return (
                                <div key={`categtagory-${tag}`}
                                     className={`game-item__label game-item__label--${tag}`}>
                                    <span className="game-item__label-info">{tag}</span>
                                </div>
                            );
                        })
                    }
                    <div className="game-item__img-container">
                        <img src={gamesServices.getImgUrl(game.image1)} alt={game.name}
                             className="game-item__game-img"/>
                    </div>
                    <div className="game-item__btn-box">
                        <div className="game-item__btn-row">
                            {!hideFavourite &&
                                <div
                                    className={`game-item__btn game-item__btn--left ${gamesServices.isFavorite(game.id) ? 'game-item__btn--favorite' : ''}`}
                                    onClick={() => {
                                        gamesServices.toggleFavorite(game.id);
                                        setRefresh((val) => ++val);
                                    }}
                                >
                                    <svg version="1.1" id="layer1" xmlns="http://www.w3.org/2000/svg"
                                         width="30px"
                                         height="30px" viewBox="0 0 30 30"
                                         style={{enableBackground: 'new 0 0 30 30'}}>
                                        <path className="small-btn-st0"
                                              d="M15,7.5l2.6,4.7l5.4,1l-3.7,3.9l0.7,5.3L15,20.2l-4.9,2.3l0.7-5.3L7,13.2l5.4-1L15,7.5"/>
                                    </svg>
                                </div>
                            }
                            <Link
                                to={playLink}
                                className="btn"
                                data-tooltip-html={tooltipText}
                                data-tooltip-id={`play-${game.id}`}
                                onClick={clickAction}
                            >
                                <T keyName="game.play">Play</T>
                            </Link>
                            <Link to={gamesServices.getDemoLink(gameSlug, currentLanguage)}
                                  className="game-item__btn game-item__btn--right"><T keyName="game.demo">Demo</T>
                            </Link>
                            {/*{tooltipText && <Tooltip id={`play-${game.id}`} />}*/}
                        </div>
                    </div>
                </div>
                <div className="game-item__title">{game.name}</div>
            </div>
        </div>
    );
};
export default Game;