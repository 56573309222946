import {useTranslate} from "@tolgee/react";

function Separator() {
    return (
        <div className="wrapper">
            <hr/>
        </div>
    );
}

function LoadMore(props) {
    const { t } = useTranslate();
    const {setEnd, batchSize} = props;
    return (
        <div className="heading">
            <div className="heading__line"></div>
            <div className="heading__content">
                <div className="btn" onClick={() => setEnd(gamesLength => gamesLength + batchSize)}>
                    <span>{t('other.load_more' ,'Load more')}</span>
                </div>
            </div>
            <div className="heading__line"></div>
        </div>
    )
};

export {Separator, LoadMore};