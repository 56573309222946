import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {s3Config} from '../config/aws.config';
import {setPopup, setShowOverlay} from '../redux/states.slice';
import helpersUtils from '../utils/helpers.utils';
import LoginPopup from './popups/LoginPopup';
import RegistrationPopup from './popups/RegistrationPopup';
import ForgotPopup from './popups/ForgotPopup';
import LogoutPopup from './popups/LogoutPopup';
import ConfirmDepositPopup from './popups/ConfirmDepositPopup';
import CurrencyPopup from './popups/CurrencyPopup';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

function PopupContainer(props) {
    const popupData = useSelector((state) => state.states.popup);
    const dispatch = useDispatch();
    const showPopup = !!popupData;

    useEffect(() => {
        showPopup && dispatch(setShowOverlay(showPopup));
        showPopup && helpersUtils.setOverlayClickAction(closePopup);
        showPopup && helpersUtils.setEscapeEventAction(closePopup);

        return () => {
            dispatch(setShowOverlay(false));
            helpersUtils.setOverlayClickAction(null);
            helpersUtils.setEscapeEventAction(null);
        };
    }, [showPopup]);

    function closePopup() {
        dispatch(setPopup(null));
    }

    if (!showPopup) return null;

    return (
        <div className={`popup ${showPopup ? 'popup--active' : ''}`}>
            <div className="popup__body">
                <div className="popup__close">
                    <div className="btn-close" onClick={closePopup}>
                        <img src={`${icons}#ico_close`} alt="" className="btn-close__img"/>
                    </div>
                </div>
                {getContent(popupData.popup, popupData.extraProps)}
            </div>
        </div>
    );
}

const LOGIN_POPUP = 'login';
const REGISTRATION_POPUP = 'registration';
const FORGOT_POPUP = 'forgot';
const LOGOUT_POPUP = 'logout';
const CONFIRM_DEPOSIT_POPUP = 'deposit';
const CURRENCY_POPUP = 'currency';

export {LOGIN_POPUP, REGISTRATION_POPUP, FORGOT_POPUP, LOGOUT_POPUP, CONFIRM_DEPOSIT_POPUP, CURRENCY_POPUP};

function getContent(popup, extraProps) {
    let content = null;

    switch (popup) {
        case LOGIN_POPUP:
            content = <LoginPopup {...extraProps} />
            break;
        case REGISTRATION_POPUP:
            content = <RegistrationPopup {...extraProps} />
            break;
        case FORGOT_POPUP:
            content = <ForgotPopup {...extraProps} />
            break;
        case LOGOUT_POPUP:
            content = <LogoutPopup {...extraProps} />
            break;
        case CONFIRM_DEPOSIT_POPUP:
            content = <ConfirmDepositPopup {...extraProps} />
            break;
        case CURRENCY_POPUP:
            content = <CurrencyPopup {...extraProps} />
            break;
        default:
            content = null;
    }

    return content;
}

export default PopupContainer;