import requestUtils from '../utils/request.utils';
import {setUserData} from '../redux/user.slice';

const usersServices = {};

usersServices.updateUserData = async (oldUserData, dispatch) => {
    const newUserData = await usersServices.info();
    newUserData && dispatch(setUserData({...oldUserData, ...newUserData}));
};

usersServices.isProfileCompleted = (userData) => {
    const requiredFields = ['email', 'name', 'surname', 'country_id', 'city', 'address', 'zip', 'gender', 'birth_date'];
    return requiredFields.every((field) => userData[field]);
};

usersServices.init = async () => {
    try {
        return requestUtils.get('/init');
    } catch (err) {
        console.error(err);
    }
};

usersServices.profile = async () => {
    try {
        return requestUtils.get('/users/profile', {}, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.edit = async (data) => {
    try {
        return requestUtils.put('/users', data, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.login = async (fields) => {
    try {
        return requestUtils.post('/users/login', fields, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.info = async () => {
    try {
        return requestUtils.get('/users/info', {}, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.logout = async (fields) => {
    try {
        return requestUtils.post('/users/logout', {}, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.register = async (fields) => {
    try {
        return requestUtils.post('/users', fields, true);
    } catch (err) {
        console.error(err);
    }
};

usersServices.getWithdrawalRequests = async () => {
    try {
        return requestUtils.post('/users/withdrawals', {}, true);
    } catch (err) {
        console.error(err);
    }
}
export default usersServices;