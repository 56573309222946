import requestUtils from '../utils/request.utils';

const paymentMethodsServices = {};

paymentMethodsServices.getPaymentMethods = async () => {
    try {
        return requestUtils.get('/paymethods', {}, true);
    } catch (err) {
        console.error(err);
    }
}

export default paymentMethodsServices;