import {useTranslate} from "@tolgee/react";
import {Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import helpersUtils from '../../utils/helpers.utils';
import {setShowMobileMenu} from '../../redux/states.slice';
import gamesServices from "../../services/games.services";

const getMenuElements = () => [
    {
        url: 'sport',
        title: 'Sport',
        translationKey: 'main.menu.elements.sport',
        extraClasses: 'disabled',
    },
    {
        url: '',
        title: 'Casino',
        translationKey: 'main.menu.elements.casino',
    },
    {
        url: 'esport',
        title: 'eSport',
        translationKey: 'main.menu.elements.e_sport',
    },
    gamesServices.getTagDetail('virtual-games'),
    gamesServices.getTagDetail('tv-games'),
    gamesServices.getTagDetail('live-games'),
    {
        url: 'tournaments',
        title: 'Tournaments',
        extraClasses: 'disabled',
        translationKey: 'main.menu.elements.tournaments',
    },
    {
        url: 'bonuses',
        title: 'Bonuses',
        extraClasses: 'disabled',
        translationKey: 'main.menu.elements.bonuses',
    }
];

function MainMenuElements(props) {
    const { t } = useTranslate()
    const location = useLocation();
    const dispatch = useDispatch();

    let currentPath = location.pathname;
    currentPath = currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

    const getMenuElement = ({title, url, extraClasses, translationKey}) => {
        let link = helpersUtils.getLanguageLink(`/${url}`);
        link = link.slice(-1) === '/' ? link.slice(0, -1) : link;

        return props.mobile ?
            (
                <li key={url} className={`mobile-menu__item ${extraClasses || ''}`}>
                    <Link onClick={() => dispatch(setShowMobileMenu(false))} to={link}  className={`nav-link ${link === currentPath ? 'nav-link--current' : ''}`}>
                        <span className="nav-link__info">{t(translationKey, title)}</span>
                    </Link>
                </li>
            )
            :
            (
                <li key={url} className={`main-nav__item ${extraClasses || ''}`}>
                    <Link onClick={() => dispatch(setShowMobileMenu(false))} to={link} className={`main-nav__link ${link === currentPath ? 'main-nav__link--active' : ''}`}>
                        <span className="main-nav__info">{t(translationKey, title)}</span>
                    </Link>
                </li>
            )
    }

    return (
        <Fragment>
            {
                getMenuElements().map(element => {
                    return getMenuElement(element);
                })
            }
        </Fragment>
    );
}

export default MainMenuElements;
