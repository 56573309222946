import {useSelector} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import helpersUtils from '../utils/helpers.utils';

const ProtectedRoutes = () => {
    const location = useLocation();
    const userData = useSelector((state) => state.user.userData);

    return userData
        ? <Outlet />
        : <Navigate to={helpersUtils.getLanguageLink('/')} replace state={{ from: location }} />;
}

export default ProtectedRoutes;