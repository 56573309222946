import {Navigate} from 'react-router-dom';
import {languageList} from '../config/languages.config';

const helpersUtils = {};

helpersUtils.language = 'en';

helpersUtils.userData = {};
helpersUtils.userProps = {};

helpersUtils.preventDefault = (event) => {
    event.preventDefault();
};

helpersUtils.getLanguageLink = (url) => {
    return `/${helpersUtils.language}${url}`;
};

helpersUtils.sleep = (timeMillis) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(), timeMillis);//returning the timeout, just for fun
    });
};

helpersUtils.uniqueID = () => {
    return (Math.random() + 1).toString(36).substring(7);
};

helpersUtils.getDateTime = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T');
    return dateArray[0] + ' ' + dateArray[1].split('.')[0];
};
helpersUtils.getDate = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T');
    return dateArray[0];
};
helpersUtils.getTime = (dateString, asString = true) => {
    const dateArray = (new Date(Date.parse(dateString))).toISOString().split('T');
    return dateArray[1].split('.')[0];
};

helpersUtils.UUIDv4Transform = (str) => {
    if (str.length === 32) return (str.slice(0, 8) + '-' + str.slice(8, 12) + '-' + str.slice(12, 16) + '-' + str.slice(16, 20) + '-' + str.slice(20));
    return str.replace(/-/g, '');
};

helpersUtils.getFormEventData = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return [target, value, name];
};

helpersUtils.onlyDigitsRegex = (string) => {
    return /^\d+$/.test(string);
};
helpersUtils.testDecimal = (string) => {
    return /^\d+(\.(\d+))?$/.test(string);
};
helpersUtils.noTagRegex = /(<([^>]+)>)/ig;
helpersUtils.isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

helpersUtils.copyTextToClipboard = (text) => {
    return navigator.clipboard.writeText(text);
};

helpersUtils.invertObject = (object) => {
    const inverted = {};
    Object.entries(object).map(([key]) => {
        inverted[object[key]] = key;
        return null;
    });

    return inverted;
};

helpersUtils.objectToQueryString = (data) => {
    return Object.entries(data).map(pair => pair.map(encodeURIComponent).join('=')).join('&');
};

helpersUtils.cutLongString = (str, maxLength) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

helpersUtils.months = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

helpersUtils.unitAmount = (subunitAmount, subunit, precision = 2) => {
    subunitAmount = typeof subunitAmount === 'string' ? parseFloat(subunitAmount) : subunitAmount;
    if (subunitAmount === 0) return 0;
    subunitAmount = subunitAmount / subunit;
    return parseFloat(subunitAmount.toFixed(precision));
};
helpersUtils.subunitAmount = (unitAmount, subunit) => {
    return Math.floor(unitAmount * subunit);
};

helpersUtils.escapeEventAction = null;
helpersUtils.setEscapeEventAction = (action) => {
    helpersUtils.escapeEventAction = action;
};
document.addEventListener('keydown', evt => {
    if (evt.key === 'Escape') {
        helpersUtils.escapeEventAction && helpersUtils.escapeEventAction();
    }
});
helpersUtils.overlayClickAction = null;
helpersUtils.setOverlayClickAction = (action) => {
    helpersUtils.overlayClickAction = action;
};

helpersUtils.getAmountParts = (balance) => {
    if (balance === 0) return ['0', '00'];
    const parts = balance.toString().split('.');
    parts[1] = parts.length === 2 ? parts[1].padEnd(2, '0') : '00';

    return parts;
};
const NavigateToHome = () => {
    return <Navigate to={`/${languageList[0]}`}/>;
};

helpersUtils.splitNameAndID = (name) => {
    const lastDash = name.lastIndexOf('-');
    return {name: name.substring(0, lastDash), id: parseInt(name.substring(lastDash + 1))};
};

helpersUtils.capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

helpersUtils.getNickname = (userData) => {
    if (userData.nickname) return userData.nickname;
    return userData.name || userData.surname;
}

export {NavigateToHome};
export default helpersUtils;