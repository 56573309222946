import Slider from 'react-slick';
import {T} from "@tolgee/react";

import {s3Config} from '../../config/aws.config';

const imgUrl = `${s3Config.assetsUrl}/img`;

function EsportsSlider() {
    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        className: 'e-sports__slider'
    };

    return (
        <section className="section">
            <div className="wrapper">
                <div className="heading">
                    <div className="heading__content">
                        <a href="#" className="heading__link">
                            <T keyName="e_sports.slider.e_sports_title">E-Sports</T>
                        </a>
                    </div>
                </div>
                <div className="e-sports">
                    <Slider {...settings}>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-1.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Dota 2</div>
                                    <div className="e-sports__event">Ultras Dota Pro League
                                        Season 7
                                    </div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-2.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Valorant</div>
                                    <div className="e-sports__event">Champions Tour
                                        Game Changers APAC
                                    </div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time">14:00</div>
                                    <div className="e-sports__date">04.08.2022</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-3.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">CS:GO</div>
                                    <div className="e-sports__event">Ultras League</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-4.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Overwatch</div>
                                    <div className="e-sports__event">Elisa Invitational</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time"><T keyName="e_sports.slider.soon">Soon</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-5.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Rainbow 6 Asia-Pacific</div>
                                    <div className="e-sports__event">South Division</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-6.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">NBA 2K</div>
                                    <div className="e-sports__event">H2H GG League. 4x5</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time">14:00</div>
                                    <div className="e-sports__date">04.08.2022</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-7.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">StarCraft</div>
                                    <div className="e-sports__event">Invitational</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-1.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Dota 2</div>
                                    <div className="e-sports__event">Ultras Dota Pro League
                                        Season 7
                                    </div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-2.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Valorant</div>
                                    <div className="e-sports__event">Champions Tour
                                        Game Changers APAC
                                    </div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time">14:00</div>
                                    <div className="e-sports__date">04.08.2022</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-3.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">CS:GO</div>
                                    <div className="e-sports__event">Ultras League</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-4.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Overwatch</div>
                                    <div className="e-sports__event">Elisa Invitational</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time">14:00</div>
                                    <div className="e-sports__date">04.08.2022</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-5.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">Rainbow 6 Asia-Pacific</div>
                                    <div className="e-sports__event">South Division</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-6.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">NBA 2K</div>
                                    <div className="e-sports__event">H2H GG League. 4x5</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time">14:00</div>
                                    <div className="e-sports__date">04.08.2022</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="e-sports__item">
                                <a href="#" className="e-sports__img-box">
                                    <img src={`${imgUrl}/e-sports/e-sports-7.jpg`} alt="" className="e-sports__img"/>
                                </a>
                                <div className="e-sports__info-box">
                                    <div className="e-sports__catagory">StarCraft</div>
                                    <div className="e-sports__event">Invitational</div>
                                    <a href="#" className="small-btn small-btn--black"><span><T keyName="e_sports.slider.open">Open</T></span></a>
                                    <div className="e-sports__time e-sports__time--live"><T keyName="e_sports.slider.live">LIVE</T></div>
                                    <div className="e-sports__date"></div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default EsportsSlider;