import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {T} from "@tolgee/react";

import {s3Config} from '../config/aws.config';
import PartnersSlider from './sliders/PartnersSlider';
import InfoMenu from './menus/InfoMenu';
import {setShowTawk} from "../redux/states.slice";

const imgUrl = `${s3Config.assetsUrl}/img`;
const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;
const logo = `${s3Config.assetsUrl}/img/logo.svg`;

const Footer = () => {
    const dispatch = useDispatch()

    return (
        <footer className="footer">
            <div className="wrapper">
                <InfoMenu />
                <div className="footer__contacts">
                    <div className="footer-contacts">
                        <ul className="footer-contacts__list">
                            <li className="footer-contacts__item">
                                <Link to="mailto:support@wagon.bet" className="footer-contacts__link">
                                    <img src={`${icons}#link_mail`} className="footer-contacts__link-img"
                                         alt="link_mail"/>
                                    <span className="footer-contacts__link-info">support@wagon.bet</span>
                                </Link>
                            </li>
                            <li className="footer-contacts__item">
                                <Link to="#" className="footer-contacts__link" onClick={() => dispatch(setShowTawk(true))}>
                                    <img src={`${icons}#link_chat_contact`}
                                         className="footer-contacts__link-img"
                                         alt="link_mail"/>
                                    <span className="footer-contacts__link-info"><T keyName="footer.live_chat">Live Chat 24/7</T></span>
                                </Link>
                            </li>
                            <li className="footer-contacts__item">
                                <Link to="tel:+372 8162 4537" className="footer-contacts__link">
                                    <img src={`${icons}#link_phone`} className="footer-contacts__link-img"
                                         alt="link_mail"/>
                                    <span className="footer-contacts__link-info">+372 8162 4537</span>
                                </Link>
                            </li>
                            <li className="footer-contacts__item">
                                <Link to="#" className="footer-contacts__link" onClick={() => window.open('https://instagram.com/wagonbet_news')}>
                                    <img src={`${icons}#link_insta`} className="footer-contacts__link-img"
                                         alt="link_mail"/>
                                    <span className="footer-contacts__link-info">@wagonbet_news</span>
                                </Link>
                            </li>
                            <li className="footer-contacts__item">
                                <Link to="#" className="footer-contacts__link"  onClick={() => window.open('https://t.me/wagonbet_en')}>
                                    <img src={`${icons}#link_telegram`} className="footer-contacts__link-img"
                                         alt="link_mail"/>
                                    <span className="footer-contacts__link-info">t.me/wagonbet_en</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <PartnersSlider/>
                <div className="footer__info-box">
                    <T keyName="footer.privacy">www.wagonbet.com operated by USPEO N.V. limited under Curaçao license №365/JAZ whose registered office is at Dr. H. Fergusonweg 1, Curaçao
                        USPEO LTD a company, whose registered office is at Riga Feraio, 7-9, LIZANTIA COURT, Flat/Office 310
                        Agioi Omologites, 1087, Nicosia, Cyprus with company number HE 434981. EU company USPEO LTD is providing payment services as an agent according to the license agreement concluded between USPEO NV and USPEO LTD"
                    </T>
                </div>
                <div className="footer__service-box">
                    <div className="footer-service">
                        <ul className="footer-service__list">
                            <li className="footer-service__item">
                                <Link to="#" className="footer-service__link">
                                    <img src={`${imgUrl}/services-img.png`} alt=""
                                         className="footer-service__link-img"/>
                                    {/*<span class="footer-service__link-info"></span>*/}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__rights">
                    <div className="footer-rights">
                        <div className="footer-rights__row">
                            <span>Copyright © {new Date().getFullYear()}</span>
                            <span className="footer-rights__logo">
						<img src={`${logo}`} className="footer-rights__img" alt="Logo"/>
						WagonBet
					</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;