import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslate} from "@tolgee/react";

import {s3Config} from '../config/aws.config';
import gamesServices from '../services/games.services';
import helpersUtils from '../utils/helpers.utils';
import {setShowSearch, setShowOverlay} from '../redux/states.slice';
import Hamburger from './menus/Hamburger';
import {LOGIN_POPUP} from './PopupContainer';

const icons = `${s3Config.assetsUrl}/img/icons/icons.svg`;

function Search() {
    const { t } = useTranslate();
    const showSearch = useSelector((state) => state.states.search);
    const userData = useSelector((state) => state.user.userData);
    const [games, setGames] = useState([]);
    const [search, setSearch] = useState('');
    const {lang} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        showSearch && gamesServices.search(search, 0, 20).then((data) => {
            console.log(data)
            setGames(data)
        });
    }, [search, showSearch])

    useEffect(() => {
        helpersUtils.setOverlayClickAction(showSearch ? closeSearch : null);
        helpersUtils.setEscapeEventAction(showSearch ? closeSearch : null);
        dispatch(setShowOverlay(showSearch));
    }, [showSearch]);

    const handleChange = (ev) => {
        const {value} = ev.target;
        setSearch(value);
    };
    const closeSearch = () => {
        dispatch(setShowSearch(false));
    }

    if (!showSearch) return null

    return (
        <div className={`search-widget ${showSearch ? 'search-widget--active' : ''}`}>
            <div className="search-widget__action-area">
                <Hamburger clickAction={closeSearch} active={true}/>
                <label className="search-area" data-label="Name" data-error="Insert name">
                    <input
                        onChange={handleChange}
                        value={search}
                        autoFocus
                        type="text"
                        className="search-area__input"
                        placeholder={t('search.search_game' ,'Search Game')}
                    />
                    <img src={`${icons}#ico_search`} className="search-area__img" alt="ico_search"/>
                </label>
            </div>
            <div className="search-widget__result-area">
                <div className="search-result">
                    <ul className="search-result__list">
                        {
                            games?.data?.map((game) => {
                                const gameSlug = gamesServices.getSlug(game.name, game.id);
                                const playLink = userData ? gamesServices.getPlayLink(gameSlug, lang) : `#${LOGIN_POPUP}`;
                                const demoLink = gamesServices.getDemoLink(gameSlug, lang);
                                return (
                                    <li key={game.id} className="search-result__item">
                                        <Link to={playLink} className="search-result__img-link" onClick={closeSearch}>
                                            <div className="game-item">
                                                <div className="game-item__box">
                                                    <div className="game-item__img-box" data-title={game.name}>
                                                        <div className="game-item__img-container">
                                                            <img src={gamesServices.getImgUrl(game.image1)} alt={game.name}
                                                                 className="game-item__game-img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="search-result__content-box">
                                            <div className="search-result__content-top">
                                                <div className="search-result__row">
                                                    <div className="search-result__row-item">
                                                        <div className="search-result__title">{game.name}</div>
                                                        <div className="search-result__sub-title">{game.gameprovider}</div>
                                                    </div>
                                                    <div className="search-result__row-item search-result__row-item--center">
                                                        <Link to={playLink} className="small-btn" onClick={closeSearch}>
                                                            Play
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="search-result__content-bottom">
                                                <div className="search-result__row">
                                                    <div className="search-result__row-item search-result__row-item--center">
                                                        <Link to={demoLink} className="search-result__btn" onClick={closeSearch}>
                                                            Demo
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Search;