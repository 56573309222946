import requestUtils from '../utils/request.utils';

const playersServices = {};

playersServices.index = async (country) => {
    try {
        return requestUtils.get('/players', {}, true);
    } catch (err) {
        console.error(err);
    }
};

playersServices.activate = async (currencyID) => {
    try {
        return requestUtils.post('/players/activate', {currency_id: currencyID}, true);
    } catch (err) {
        console.error(err);
    }
}


export default playersServices;