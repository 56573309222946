import {T, useTranslate} from "@tolgee/react";

import React, { useEffect, useState } from 'react';

// Placeholder Local Data
const placeholderMessage = `ПОЗДРАВЛЯЕМ! Вы получили: 20 фриспинов в игре <a href="#">Sahara Night</a>`;
const placeholderTableArr = [
    {
        dateReceived: '20:45 02:02:2023',
        bonusType: '20 фриспинов в игре Sahara Night',
    },
    {
        dateReceived: '18:00 14:01:2023',
        bonusType: '100 фриспинов “Неделя NetEnt”',
    },
    {
        dateReceived: '23:55 31:12:2022',
        bonusType: '100% на депозит “Новогодняя акция”',
    },
    {
        dateReceived: '09:36 22:11:2022',
        bonusType: '50% на депозит от Vovan',
    },
];

function AccountPromocodes() {
    const { t } = useTranslate();
    const [allertMessage, setAllertMessage] = useState(null);
    const [bonusHistory, setBonusHistory] = useState(null);

    useEffect(() => {
        setAllertMessage(placeholderMessage);
        setBonusHistory(placeholderTableArr);
    }, []);

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName="account.promo_code.promo_code_title">Promocode</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__body">
                                <div className="account__shape">
                                    {allertMessage &&
                                        <div className="allert-message">
                                            <span>{allertMessage}</span>
                                        </div>
                                    }
                                    <form className="form account__form">
                                        <fieldset>
                                            <label className="input-area" data-label={t('account.promo_code.enter_promo_code', 'Enter promo code')} data-error={t('account.promo_code.enter_promo_code', 'Enter promo code')}>
                                                <input type="text" className="input-area__input" placeholder={t('account.promo_code.enter_promo_code', 'Enter promo code')} />
                                            </label>
                                            <div className="btn-row">
                                                <label className="btn btn--medium">
                                                    <input type="submit" />
                                                    <span><T keyName="account.promo_code.confirm">Confirm</T></span>
                                                </label>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                {bonusHistory &&
                                    <div className="account__shape account__shape--large">
                                        <h3 className="ttu"><T keyName="account.promo_code.promo_code_history">History of promo codes</T></h3>
                                        <div className="table-container">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th><T keyName="account.promo_code.received">Received</T></th>
                                                        <th><T keyName="account.promo_code.bonus">Bonus</T></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bonusHistory.map((bonus) => {
                                                            return (
                                                                <tr key={bonus.dateReceived}>
                                                                    <td>{bonus.dateReceived}</td>
                                                                    <td>{bonus.bonusType}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AccountPromocodes;