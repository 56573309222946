import {useEffect} from 'react';

import SportSlider from '../components/sliders/SportSlider';
import GameTags from './GameTags';
import {Separator} from '../components/Other';
import FastDeposit from '../components/FastDeposit';
import ProvidersSlider from '../components/sliders/ProvidersSlider';
import EsportsSlider from '../components/sliders/EsportsSlider';

function Home() {
    useEffect(() => {
        //gamesServices.getByProvider('netent').then((data) => console.log(data))
    }, [])

    return (
        <main>
            <FastDeposit />
            <SportSlider />
            <GameTags tag='popular' batchSize={12}/>
            <Separator />
            <GameTags tag='instant-win' batchSize={12} />
            <Separator />
            <ProvidersSlider />
            <Separator />
            <GameTags tag='live-games' batchSize={12}/>
            <Separator />
            <GameTags tag='bonus-buy' batchSize={12}/>
            <Separator />
            <GameTags tag='books' batchSize={12}/>
            <Separator />
            <EsportsSlider />
        </main>
    );
}

export default Home;