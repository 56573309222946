function Sport() {
    return (
        <main className="main">
            <div className="iframe-box">
                <iframe allowFullScreen="" allow="autoplay" width="1" height="2753"
                        src="//sport.wagonbet.com/SportsBook/Home?token=-&amp;d=d&amp;l=en&amp;tz=&amp;of=0&amp;ofl=&amp;customCssUrl=&amp;sportsBookView=europeanView&amp;clearSiteStyles=false&amp;resetAllStyles=false&amp;theme="
                        style={{minWidth: '100%', minHeight: '647px', display: 'block'}}>
                </iframe>
            </div>
        </main>
    )
}

export default Sport;