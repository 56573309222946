function Avatar() {
    return (
        <svg className="user__img"  version="1.1"
             id="layeer_1" x="0px" y="0px" width="36px" height="36px"
             viewBox="0 0 36 36" style={{enableBackground: "new 0 0 36 36"}}
        >
            <path id="user" style={{fill: "#E4E6EB"}}
                  d="M18,3C8,3,0,11.1,0,21.1c0,4.6,1.7,8.8,4.5,11.9c0.1-3.3,3.2-6.1,7.6-7.5C13.7,27,15,28,18,28  s4.3-1,5.9-2.5c4.4,1.4,7.5,4.2,7.6,7.5c2.8-3.2,4.5-7.4,4.5-11.9C36,11.1,28,3,18,3z M21.1,24.9c-0.9,0.5-2.1,0.8-3.1,0.8  s-2.2-0.3-3.1-0.8c-2.4-1.3-4-4-4-7.2c0-4.4,3.1-8,7.1-8s7.1,3.6,7.1,8C25.1,20.8,23.5,23.6,21.1,24.9z">
            </path>
        </svg>
    )
}

export default Avatar;