import {_t} from '../../utils/translate.utils';
import {useEffect, useState} from 'react';

function StyledSelect (props) {
    const [isActive, setIsActive] = useState(false);
    const {options = [], title, fieldName, error, selectedValue, setValue, extraClasses = '', tabIndex = null} = props;

    useEffect(() => {
        setIsActive(false);
    }, [selectedValue]);

    return (
        <div className={`input-area ${error ? 'input-area--error' : ''} ${extraClasses}`} data-label={title} data-error={error}>
            <div className={`input-drop-down ${isActive ? 'input-drop-down--active' : ''}`}>
                <div
                    className="input-drop-down__current"
                    onClick={() => {
                        setIsActive(isActive => !isActive)
                    }}
                >
                    {selectedValue ? options.find(option => option.value === selectedValue).label : title}
                </div>
                <ul className="input-drop-down__list">
                    {
                        options.map((option) => {
                            return (
                                <li key={option.value} className="input-drop-down__item">
                                    <label className="input-drop-down__label">
                                        <input
                                            type="radio"
                                            checked={option.value === selectedValue}
                                            name={fieldName}
                                            value={option.value}
                                            className="input-drop-down__radio"
                                            onClick={() => {
                                                setIsActive(isActive => !isActive);
                                                setValue && setValue(option.value);
                                            }}
                                            tabIndex={tabIndex}
                                        />
                                        <span className="input-drop-down__content">
                                            <span
                                                className="input-drop-down__info">{option.label}</span>
                                        </span>
                                    </label>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )

}

export default StyledSelect;