import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {format} from 'date-fns';
import {T, useTranslate} from "@tolgee/react";

import Select from '../../components/ui/Select';
import playTransactionsServices from '../../services/play_transactions.services';
import playersServices from '../../services/players.services';
import gamesServices from '../../services/games.services';
import helpersUtils from '../../utils/helpers.utils';

const BATCH_SIZE = 50;
const DEFAULT_ORDER = ['created_at', 'desc'];

const AccountGamesHistory = () => {
    const {t} = useTranslate()
    const [playTransactions, setPlayTransactions] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [players, setPlayers] = useState([]);
    const [playerID, setPlayerID] = useState(null);
    const [start,] = useState(0);
    const [end, setEnd] = useState(BATCH_SIZE);
    const [order,] = useState(DEFAULT_ORDER);
    const {lang: currentLanguage} = useParams();

    useEffect(() => {
        playersServices.index().then(data => {
            if (!data) return;
            const currenciesData = {};
            data.forEach(player => {
                currenciesData[player.currency_id] = player;
            })
            setCurrencies(currenciesData);
            setPlayers(data);
        });
    }, []);

    useEffect(() => {
        const filters = {
            p: {
                start,
                end
            },
            f: {
                currency_id: players[playerID]?.currency_id || undefined
            }
        };
        playTransactionsServices.index(filters).then(transactions => {
            setPlayTransactions(transactions);
        });
    }, [start, end, order, playerID]);

    if (!currencies || !playTransactions) return null;

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName="account.games.history.games_history_title">Games history</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="account__filter-box">
                                        <form className="form" action="">
                                            <div className="account__filter-row">
                                                <div className="account__filter-item">
                                                    {/*<label className="input-area" data-label="Дата ставки от"
                                                           data-error="Дата ставки от">
                                                        <input type="text" className="input-area__input"
                                                               placeholder="Дата ставки от"/>
                                                    </label>
                                                    <label className="input-area" data-label="Дата ставки до"
                                                           data-error="Дата ставки до">
                                                        <input type="text" className="input-area__input"
                                                               placeholder="Дата ставки до"/>
                                                    </label>*/}
                                                </div>
                                                <div className="account__filter-item">
                                                    <div className="select-row">
                                                        <label className={`select`}>
                                                            <Select
                                                                name="currencies"
                                                                includeEmpty={t('account.games.history.currency', 'Currency')}
                                                                options={Object.keys(players).map(playerID => {
                                                                    return {
                                                                        name: players[playerID].currency_code,
                                                                        value: playerID
                                                                    };
                                                                })}
                                                                setValue={(value) => {
                                                                    setPlayerID(value);
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th><span className="table__sort"><T keyName="account.games.history.bet_date_header">Bet date</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.finish_date_header">Finish date</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.game_header">Game</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.bet_header">Bet</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.win_header">Win</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.balance_header">Games history</T></span></th>
                                                <th><span className="table__sort"><T keyName="account.games.history.currency">Currency</T></span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                playTransactions && playTransactions.data.length > 0 && playTransactions.data.map((transaction) => {
                                                    const game = gamesServices.getByGameId(transaction.game_id);
                                                    const slug = gamesServices.getSlug(game.name, game.id);
                                                    const gameLink = gamesServices.getPlayLink(slug, currentLanguage);
                                                    const currencyData = currencies[transaction.currency_id];
                                                    const bet = transaction.bet  ?
                                                        helpersUtils.unitAmount(transaction.bet, currencyData.currency_subunit, currencyData.currency_decimal_digits)
                                                        :
                                                        0;
                                                    const win = transaction.win  ?
                                                        helpersUtils.unitAmount(transaction.win, currencyData.currency_subunit, currencyData.currency_decimal_digits)
                                                        :
                                                        0;
                                                    const balance = helpersUtils.unitAmount(transaction.balance, currencyData.currency_subunit, currencyData.currency_decimal_digits);
                                                    return (
                                                        <tr
                                                            key={`key-${transaction.created_at}`}
                                                        >
                                                            <td>{format(new Date(transaction.created_at), 'dd.MM.yyyy HH:mm:ss')}</td>
                                                            <td>{format(new Date(transaction.modified_at), 'dd.MM.yyyy HH:mm:ss')}</td>
                                                            <td><Link to={gameLink}>{game.name}</Link></td>
                                                            <td>{bet}</td>
                                                            <td><b className="color-decor">{win}</b></td>
                                                            <td>{balance}</td>
                                                            <td>{currencyData.currency_code}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        playTransactions.pagination.total > end && (
                                            <div className="btn-row">
                                                <div
                                                    className="btn btn--black"
                                                    onClick={() => {
                                                        setEnd(end => end + BATCH_SIZE);
                                                    }}
                                                >
                                                    <span><T keyName="account.games.history.show_more">Show more</T></span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default AccountGamesHistory;