import MainMenu from './menus/MainMenu';
import CategoriesMenu from './menus/CategoriesMenu';

export default function () {
    return (
        <header className="header">
            <div className="header__fixed">
                <MainMenu />
                <CategoriesMenu />
            </div>
        </header>
    );
}