import requestUtils from '../utils/request.utils';

const freespinsServices = {}

freespinsServices.getPlayerFreeSpines = async (filters) => {
    try {
        return requestUtils.get('/freespins', filters, true);
    } catch (err) {
        console.error(err);
    }
}

freespinsServices.cancel = async (uuid) => {
    try {
        console.log(uuid)
        return requestUtils.post(`/freespins/cancel/${uuid}`,{}, true);
    } catch (err) {
        console.error(err);
    }
}
export default freespinsServices;