import {useSelector} from 'react-redux';

import helpersUtils from '../utils/helpers.utils';

const Overlay = () => {
    const showOverlay = useSelector((state) => state.states.overlay);

    return (
        <div className={`overlay ${showOverlay ? 'overlay--active' : ''}`}
             onClick={() => helpersUtils.overlayClickAction()}></div>
    );
};

export default Overlay;