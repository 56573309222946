import InfoMenuElements from './InfoMenuElements';

const InfoMenu = () => {

    return (
        <div className="footer__nav">
            <hr/>
            <div className="footer-nav">
                <div className="wrapper">
                    <ol className="footer-nav__list">
                        <InfoMenuElements />
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default InfoMenu;