import helpersUtils from './helpers.utils';

const getHomeLink = () => helpersUtils.getLanguageLink('/');
const getProfileLink = () => helpersUtils.getLanguageLink('/account/profile');
const getDepositLink= () => helpersUtils.getLanguageLink('/account/deposit');

export {
    getHomeLink,
    getProfileLink,
    getDepositLink
};