import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {T} from "@tolgee/react";

import {s3Config} from '../config/aws.config';
import {setShowTawk} from '../redux/states.slice';

function Tawk() {
    const showTawk = useSelector((state) => state.states.tawk);
    const [loaded, setLoaded] = useState(false);
    const tawkMessengerRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loaded) {
            showTawk ? tawkMessengerRef.current.maximize() : tawkMessengerRef.current.minimize()
        }
    }, [showTawk, loaded])
    const onLoad = () => {
        tawkMessengerRef.current?.hideWidget();
        setLoaded(true);
    }

    const onChatMinimized = () => {
        dispatch(setShowTawk(false));
    }

    const onChatMaximized = () => {
        dispatch(setShowTawk(true));
    }

    return (
        <div className="small-btn small-btn--white btn-chat" onClick={() => dispatch(setShowTawk(!showTawk))}>
            <img src={`${s3Config.assetsUrl}/img/icons/icons.svg#link_chat`} alt="" className="small-btn__img" />
            <span><T keyName='tawk.live_chat'>Live Chat</T></span>
            <TawkMessengerReact
                propertyId="623c94092abe5b455fc17980"
                widgetId="1fuua4hvg"
                ref={tawkMessengerRef}
                onLoad={onLoad}
                onChatMinimized={onChatMinimized}
                onChatMaximized={onChatMaximized}
                customStyle={{
                    visibility : {
                        desktop : {
                            xOffset : 15,
                            yOffset : 15,
                            position : 'br'
                        },
                        mobile : {
                            xOffset : 15,
                            yOffset : 15,
                            position : 'br'
                        }
                    }
                }}
            />
        </div>
    );
}

export default Tawk;