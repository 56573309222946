import React, { useEffect, useState } from 'react';
import {T} from "@tolgee/react";

import BonusCard from '../../components/BonusCard';
import SmallButton from '../../components/ui/SmallButton';

// Placeholder local Arr
const placeholderSportBonuses = [
    {
        id: 0,
        imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/02.jpg',
        title: '100% приветсвенный бонус до 150000 рублей на депозит',
        info: 'В SimCasino каждый найдет бонус на свой вкус. Соверши первое пополнение счета и получи 100% бонус, а также до 50 фриспинов в подарок на второй день!',
    },
    {
        id: 1,
        imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/03.jpg',
        title: '200% приветсвенный бонус до 150000 рублей на депозит',
        info: '',
    },
    {
        id: 2,
        imgUrl: 'https://weblayouts.s3.eu-central-1.amazonaws.com/demo-projects/sim-casino/img/promo/02.jpg',
        title: '150% приветсвенный бонус до 150000 рублей на депозит',
        info: 'В SimCasino каждый найдет бонус на свой вкус. Соверши первое пополнение счета и получи 100% бонус, а также до 50 фриспинов в подарок на второй день!',
    },
];
const placeholderSportTableBonuses = [
    {
        id: 0,
        name: 'Еженедельный бонус',
        dateReceived: '[10:05] 21.09.2022',
        dateExpired: '[10:05] 31.09.2022',
        amount: '1 000 EUR',
        wagering: 0,
    },
    {
        id: 1,
        name: 'Еженедельный бонус 2',
        dateReceived: '[10:05] 21.09.2022',
        dateExpired: '[10:05] 31.09.2022',
        amount: '1 000 EUR',
        wagering: 0,
    },
    {
        id: 2,
        name: 'Еженедельный бонус 3',
        dateReceived: '[10:05] 21.09.2022',
        dateExpired: '[10:05] 31.09.2022',
        amount: '1 000 EUR',
        wagering: 0,
    },
]


const AccountSportBonuses = () => {
    const [sportBonuses, setSportBonuses] = useState(null);

    useEffect(() => {
        setSportBonuses(placeholderSportBonuses)
    }, [])

    if (!sportBonuses) return null;

    return (
        <main className="main">
            <section className="section">
                <div className="wrapper">
                    <div className="heading">
                        <div className="heading__content">
                            <h2><T keyName='account.sport.bonuses'>Sport bonuses</T></h2>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__grid">
                            <div className="account__bonus-grid">
                                {
                                    sportBonuses.map((bonus) => {
                                        return (
                                            <BonusCard
                                                key={bonus.title}
                                                vertical
                                                imgUrl={bonus.imgUrl}
                                                title={bonus.title}
                                                info={bonus.info}
                                            >
                                                <SmallButton>
                                                    <span><T keyName='account.sport.activate'>Activate</T></span>
                                                </SmallButton>
                                                <SmallButton black>
                                                    <span><T keyName='account.sport.refuse'>Refuse</T></span>
                                                </SmallButton>
                                            </BonusCard>
                                        );
                                    })
                                }
                            </div>
                            <div className="account__body account__body--active">
                                <div className="account__shape account__shape--large">
                                    <div className="heading">
                                        <div className="heading__content">
                                            <h2><T keyName='account.sport.active_bonus'>Active bonuses</T></h2>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th><T keyName='account.sport.active_bonus_type_header'>Types</T></th>
                                                    <th><T keyName='ccount.casino.bonuses.received_expires_header'>Received / expires</T></th>
                                                    <th><T keyName='account.sport.active_bonus_amount_header'>Active bonuses</T></th>
                                                    <th><T keyName='account.casino.bonuses.bonus_wagering_header'>Bonus wagering</T></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <SmallButton black>
                                                            <span><T keyName='account.casino.bonuses.delete'>Delete</T></span>
                                                        </SmallButton>
                                                    </td>
                                                    <td>
                                                        <a href="#">{placeholderSportTableBonuses[0].name}</a>
                                                    </td>
                                                    <td>
                                                        {placeholderSportTableBonuses[0].dateReceived}
                                                        <br />
                                                        {placeholderSportTableBonuses[0].dateExpired}
                                                    </td>
                                                    <td>{placeholderSportTableBonuses[0].amount}</td>
                                                    <td>{placeholderSportTableBonuses[0].wagering}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <p>
                                        <T>При нажатии на кнопку <b>”УДАЛИТЬ”</b> вы отказываетесь от использования данного бонуса.</T>
                                        <br />
                                        <T>После <b>”АКТИВАЦИИ”</b> бонуса отменить бонус будет невозможно.</T>
                                    </p>
                                </div>
                            </div>
                            <div className="account__body">
                                <div className="account__shape account__shape--large">
                                    <div className="heading">
                                        <div className="heading__content">
                                            <h2><T keyName='account.sport.availabe_bonus'>Available bonuses</T></h2>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th><T keyName='account.sport.active_bonus_type_header'>Types</T></th>
                                                    <th><T keyName='account.sport.received_expires_header'>Received / expires</T></th>
                                                    <th><T keyName='account.sport.active_bonus_amount_header'>Active bonuses</T></th>
                                                    <th><T keyName='account.sport.bonus_wagering_header'>Bonus wagering</T></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    placeholderSportTableBonuses.map((bonus) => {
                                                        return (
                                                            <tr key={bonus.id}>
                                                                <td>
                                                                    <div className="btn-row btn-row--no-padding">
                                                                        <SmallButton>
                                                                            <span><T keyName='account.sport.unite'>Unite</T></span>
                                                                        </SmallButton>
                                                                        <SmallButton black>
                                                                            <span><T keyName='account.sport.replace'>Replace</T></span>
                                                                        </SmallButton>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#">{bonus.name}</a>
                                                                </td>
                                                                <td>
                                                                    {bonus.dateReceived}
                                                                    <br />
                                                                    {bonus.dateExpired}
                                                                </td>
                                                                <td>{bonus.amount}</td>
                                                                <td>{bonus.wagering}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <p>
                                        <T>При нажатии на кнопку <b>”ОБЪЕДИНИТЬ”</b> сумма нового бонуса суммируется с активным бонусом.</T>
                                        <br />
                                        <T>При нажатии на кнопку <b>”ЗАМЕНИТЬ”</b> произойдёт анулирование и замена текущего бонуса, а также его отыгрыша (прокрутки) на новый.</T>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AccountSportBonuses;