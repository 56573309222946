import {Link} from 'react-router-dom';
import CategoriesMenuElements from './CategoriesMenuElements';
import helpersUtils from '../../utils/helpers.utils';

function CategoriesMenu() {
    return (
        <div className="header__center">
            <div className="them-filter">
                <ol className="them-filter__list">
                    <CategoriesMenuElements />
                </ol>
                <div className="them-filter__sub-menu">
                    <div className="them-filter__catalog them-filter__catalog--them">
                        <div className="them-catalog">
                            <div className="wrapper">
                                <ul className="them-catalog__list">
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_fantastic.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>фантастика</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_fantasy.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Фэнтэзи</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_sport.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>спорт</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link them-catalog__link--active">
                                            <img src="img/them-filter/theme_history.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>История и мифы</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_romance.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>Романтические</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_vacation.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>Летний отдых</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_animals.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>Мир животных</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_adventure.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>Приключения</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_fruit.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Фрукты</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_movie.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span
                                                    className="them-catalog__link-info"><span>Кино и ТВ-шоу</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_luxury.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Роскошь</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_horror.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Ужасы</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_east.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Восточные</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_holiday.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Праздники</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_classic.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Ретро</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_casual.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Казуальные</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_aqua.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Водный мир</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_egypt.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Египет</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_race.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Гонки</span></span>
                                        </Link>
                                    </li>
                                    <li className="them-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="them-catalog__link">
                                            <img src="img/them-filter/theme_lepricon.jpg" alt=""
                                                 className="them-catalog__link-img" />
                                                <span className="them-catalog__link-info"><span>Леприкон</span></span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="them-filter__catalog them-filter__catalog--provider">
                        <div className="provider-catalog">
                            <div className="wrapper">
                                <div className="provider-catalog__grid">
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Ainsworth.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Ainsworth</span>
                                            <span className="provider-catalog__link-count">112</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link provider-catalog__link--active">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Amanet.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Amanet</span>
                                            <span className="provider-catalog__link-count">2</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/BetDigital.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">BetDigital</span>
                                            <span className="provider-catalog__link-count">62</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/BetSoft.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">BetSoft</span>
                                            <span className="provider-catalog__link-count">69</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/BigTimeGaming.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">BigTimeGaming</span>
                                            <span className="provider-catalog__link-count">29</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/BoomingGames.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">BoomingGames</span>
                                            <span className="provider-catalog__link-count">239</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/CasinoTechnology.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">CasinoTechnology</span>
                                            <span className="provider-catalog__link-count">2239</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Elk.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Elk</span>
                                            <span className="provider-catalog__link-count">39</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Endorphina.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Endorphina</span>
                                            <span className="provider-catalog__link-count">319</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Gamevy.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Gamevy</span>
                                            <span className="provider-catalog__link-count">9</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/IronDog.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">IronDog</span>
                                            <span className="provider-catalog__link-count">39</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/LightingBox.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">LightingBox</span>
                                            <span className="provider-catalog__link-count">129</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Microgaming.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Microgaming</span>
                                            <span className="provider-catalog__link-count">89</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Netent.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Netent</span>
                                            <span className="provider-catalog__link-count">989</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/NextGen.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">NextGen</span>
                                            <span className="provider-catalog__link-count">100</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Playson.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Playson</span>
                                            <span className="provider-catalog__link-count">960</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/PragmaticPlay.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">PragmaticPlay</span>
                                            <span className="provider-catalog__link-count">78</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Quickfire.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Quickfire</span>
                                            <span className="provider-catalog__link-count">58</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/SideCity.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">SideCity</span>
                                            <span className="provider-catalog__link-count">63</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Spinomenal.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Spinomenal</span>
                                            <span className="provider-catalog__link-count">623</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/Thunderkick.svg" alt=""
                                         className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">Thunderkick</span>
                                            <span className="provider-catalog__link-count">48</span>
                                        </Link>
                                    </div>
                                    <div className="provider-catalog__item">
                                        <Link to={`/${helpersUtils.getLanguageLink('/games/slots')}`} className="provider-catalog__link">
								<span className="provider-catalog__link-img-box">
									<img src="img/providers/x2Gaming.svg" alt="" className="provider-catalog__link-img" />
								</span>
                                            <span className="provider-catalog__link-info">x2Gaming</span>
                                            <span className="provider-catalog__link-count">42</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CategoriesMenu;
