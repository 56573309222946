import React from 'react'

function Select(props) {
    const {name, id, options, customSelectClass, setValue, includeEmpty, selectedValue} = props;

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setValue && setValue(value);
    }
    return (
        <select
            value={selectedValue}
            onChange={handleSelectChange}
            name={name}
            id={id || undefined}
            className={customSelectClass ||"select__select"}
        >
            {
                includeEmpty ? (
                    <option value="">{includeEmpty}</option>
                ) : ''
            }
            {
                options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>{option.name}</option>
                    )
                })
            }
        </select>
    )
}

export default Select;